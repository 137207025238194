import React from "react";

const IslamicCounselling = () => {
  return (
    <div>
      <h1>Islamic Counselling</h1>
      <p>
        Welcome to our Islamic Counselling page. Here you can find information
        about our counselling services.
      </p>
      {/* Add more content specific to Islamic counselling */}
    </div>
  );
};

export default IslamicCounselling;
