import React from "react";
import { Button } from "components/shadcn/Button";
import { cn } from "components/shadcn/lib/utils";
import { dateUtils } from "../utils";

const DayGrid = ({
  currentDate,
  onSelectDay,
  selectedDate,
  daysWithSlots = [],
}) => {
  const totalDays = dateUtils.getDaysInMonth(currentDate);
  const dayOffset = dateUtils.getDayOffset(currentDate);

  const renderDays = () => {
    const days = [];

    // Empty cells for days before the first of the month
    for (let i = 0; i < dayOffset; i++) {
      days.push(<div key={`empty-${i}`} className="h-12" />);
    }

    // Days of the month
    for (let i = 1; i <= totalDays; i++) {
      const dayDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        i,
      );
      const dateString = dayDate.toISOString().split("T")[0];

      const isSelected =
        selectedDate &&
        selectedDate.getDate() === i &&
        selectedDate.getMonth() === currentDate.getMonth() &&
        selectedDate.getFullYear() === currentDate.getFullYear();

      // Changed from .has() to .includes() since we're working with an array
      const hasSlots = daysWithSlots.includes(dateString);

      days.push(
        <Button
          key={i}
          variant={isSelected ? "default" : "ghost"}
          className={cn(
            "h-12 w-12 p-0 font-normal aria-selected:opacity-100",
            hasSlots &&
              "bg-accent hover:bg-accent hover:text-accent-foreground",
            isSelected &&
              "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground",
            !hasSlots && "text-muted-foreground cursor-not-allowed opacity-50",
          )}
          disabled={!hasSlots}
          onClick={() => hasSlots && onSelectDay(i)}
        >
          {i}
        </Button>,
      );
    }

    return days;
  };

  return <div className="grid grid-cols-7 gap-1">{renderDays()}</div>;
};

export default DayGrid;
