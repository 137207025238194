import { Link } from "react-router-dom";
import { Button } from "components/shadcn/Button";
import { BentoDemo } from "components/landing/Bento";

export default function Component() {
  return (
    <section className="relative min-h-screen w-full overflow-hidden">
      <div className="container relative flex min-h-screen flex-col items-start justify-center space-y-8 px-4 py-24 md:px-6 lg:px-8">
        <div className="max-w-[750px]">
          <h1 className="text-6xl font-bold tracking-tighter text-white md:text-6xl lg:text-7xl">
            Empower the Community through your Mosque
          </h1>
          <p className="max-w-[600px] text-lg text-white md:text-xl">
            Millions of people rely on local mosques for guidance and support.
            Start building a stronger community today.
          </p>
        </div>
        <div className="flex flex-wrap gap-4">
          <Button
            asChild
            size="lg"
            className="bg-black text-white shadow-sm hover:bg-gray-800"
          >
            <Link
              to="https://platform.mosquely.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Now
            </Link>
          </Button>
          <Button
            asChild
            size="lg"
            variant="outline"
            className="border-gray-500 text-gray-500 hover:bg-gray-100"
          >
            <Link to="#">Learn More</Link>
          </Button>
        </div>
      </div>
      <div className="p-4 md:p-6 lg:p-8">
        <BentoDemo />
      </div>
    </section>
  );
}
