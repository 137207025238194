import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
} from "components/shadcn/Card"; // Assuming you have ShadCN components in this path

const TermsOfService = () => {
  return (
    <div className="space-y-6 p-6">
      <Card>
        <CardHeader>
          <CardTitle>Terms of Service</CardTitle>
        </CardHeader>
        <CardContent>
          <section>
            <h2 className="text-lg font-semibold mb-2">1. Introduction</h2>
            <p>
              Welcome to Mosquely. By accessing or using our website and
              services, you agree to comply with and be bound by these Terms of
              Service. Please read them carefully.
            </p>
          </section>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>2. Acceptance of Terms</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            By registering for an account, making payments, donations, or using
            any of our services, you accept these Terms of Service. If you do
            not agree with any part of these terms, you must not use our
            services.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>3. User Responsibilities</CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="list-disc pl-6 space-y-1">
            <li>
              <strong>Accurate Information:</strong> You agree to provide
              accurate and complete information when creating an account or
              making transactions.
            </li>
            <li>
              <strong>Account Security:</strong> You are responsible for
              maintaining the confidentiality of your account credentials and
              for all activities that occur under your account.
            </li>
            <li>
              <strong>Compliance:</strong> You agree to use the services only
              for lawful purposes and in accordance with these Terms.
            </li>
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>4. Payment and Donations</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            All payments, donations, and zakat contributions processed through
            our platform are subject to the applicable financial regulations and
            our payment policies. We reserve the right to refuse or cancel
            transactions at our discretion.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>5. Service Bookings</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            You may book hall services, nikah services, and other offerings
            through our platform. All bookings are subject to availability and
            confirmation. We are not responsible for any scheduling conflicts or
            cancellations initiated by third parties.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>6. Content Uploads</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            Users may upload news articles, videos, lectures, and other content
            to the website. By uploading content, you grant us a non-exclusive,
            royalty-free license to use, reproduce, and display the content as
            part of our services. You are responsible for ensuring that your
            content does not infringe on any third-party rights or violate any
            laws.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>7. Intellectual Property</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            All content on the website, including text, graphics, logos, and
            software, is the property of Mosquely or its licensors and is
            protected by intellectual property laws. You may not reproduce,
            distribute, or create derivative works without our explicit
            permission.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>8. Termination</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            We reserve the right to terminate or suspend your account and access
            to our services at our sole discretion, without notice, for conduct
            that we believe violates these Terms or is harmful to other users or
            the organization.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>9. Limitation of Liability</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            To the fullest extent permitted by law, Mosquely shall not be liable
            for any indirect, incidental, special, consequential, or punitive
            damages arising out of your use of the services.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>10. Indemnification</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            You agree to indemnify and hold harmless Mosquely, its affiliates,
            officers, and employees from any claims, liabilities, damages, and
            expenses arising out of your use of the services or violation of
            these Terms.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>11. Governing Law</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the UK, without regard to its conflict of law provisions.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>12. Changes to Terms</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            We may update our Terms of Service from time to time. Any changes
            will be posted on this page, and the "Last Updated" date will be
            revised accordingly. Continued use of the services after any changes
            constitutes acceptance of the new Terms.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>13. Contact Us</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            If you have any questions about these Terms of Service, please
            contact us at:
          </p>
          <ul className="list-disc pl-6 space-y-1">
            <li>
              <strong>Email:</strong> admin@mosquely.co.uk
            </li>
            <li>
              <strong>Phone:</strong> +44 74 5502 5053
            </li>
          </ul>
        </CardContent>
      </Card>
    </div>
  );
};

export default TermsOfService;
