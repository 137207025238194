import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formData: {},
  currentStep: 1,
};

export const nikahSlice = createSlice({
  name: "nikah",
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    nextStep: (state) => {
      state.currentStep += 1;
    },
    prevStep: (state) => {
      state.currentStep -= 1;
    },
    resetForm: (state) => {
      state.formData = {};
      state.currentStep = 1;
    },
    navigateTo: (state, action) => {
      state.currentStep = action.payload;
    },
    saveNikahDetails: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
      // You can add any additional logic here for saving the details
    },
  },
});

export const {
  updateFormData,
  nextStep,
  prevStep,
  resetForm,
  navigateTo,
  saveNikahDetails,
} = nikahSlice.actions;

export default nikahSlice.reducer;
