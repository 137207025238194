import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navigateTo } from "store/nikahSlice";
import { FaRing } from "react-icons/fa";
import { GiPartyPopper } from "react-icons/gi";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/shadcn/Card";
import { Button } from "components/shadcn/Button";

const Marriage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (service) => {
    dispatch(navigateTo(service));
    navigate(`/dashboard/attendee/services/marriage/${service}`);
  };

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Marriage Services</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Button
          variant="outline"
          className="w-full flex items-center gap-4 py-6 text-lg"
          onClick={() => handleNavigation("nikah")}
        >
          <FaRing className="h-5 w-5" />
          <span>Nikah</span>
        </Button>

        <Button
          variant="outline"
          className="w-full flex items-center gap-4 py-6 text-lg"
          onClick={() => handleNavigation("ceremony")}
        >
          <GiPartyPopper className="h-5 w-5" />
          <span>Ceremony</span>
        </Button>
      </CardContent>
    </Card>
  );
};

export default Marriage;
