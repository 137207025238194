import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  createPaymentIntent,
  createBooking,
  clearError,
} from "store/bookingSlice";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/shadcn/Card";
import MarriageForm from "./MarriageForm";
import SpacesForm from "./SpacesForm";
import PaymentForm from "./PaymentForm";
import { Button } from "components/shadcn/Button";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Calendar from "./calendar/Calendar";

const stripePromise = loadStripe(
  "pk_test_51PZuvXAaQWBe3HS2gG6nxAOnFzHmL24mI3IgCEULbmXULH3MNWJWG8bTo5FW5V1fYeG2P291pzRA74fPWszoUoGF00AU1LnDcD",
);

function Booking() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mosque_glide_id = searchParams.get("mosque_glide_id");
  const user_glide_id = searchParams.get("user_glide_id");
  const purpose = searchParams.get("purpose");

  const dispatch = useDispatch();
  const { clientSecret, loading, error, successMessage } = useSelector(
    (state) => state.booking,
  );

  const handleFormSubmit = (formData) => {
    if (purpose === "marriage") {
      const bookingPayload = {
        resource_id: "22222222-2222-2222-2222-222222222222",
        user_glide_id,
        mosque_glide_id,
        booking_data: {
          name: formData.name,
          phone_number: formData.phone_number,
          purpose: "marriage",
          booking_slot_ids: ["d025693a-966b-4c8e-8f68-4346f6fceadb"],
        },
      };
      dispatch(createBooking(bookingPayload));
    } else {
      const payload = {
        user_glide_id,
        mosque_glide_id,
        resource_id: "33333333-3333-3333-3333-333333333333",
        space_ids: formData.selectedSpaces,
        booking_data: {
          name: formData.name,
          purpose: formData.purpose_of_hire,
          booking_slot_ids: ["d025693a-966b-4c8e-8f68-4346f6fceadb"],
        },
        payment_type: formData.payment_type,
        amount: formData.totalCost,
      };

      dispatch(createPaymentIntent(payload));
    }
  };

  return (
    <Card className="max-w-md mx-auto p-4">
      <Calendar
        daysWithSlots={new Set(["2024-11-01", "2024-11-15"])}
        isLoading={false}
      />{" "}
      <CardHeader>
        <CardTitle>Simple Booking</CardTitle>
      </CardHeader>
      <CardContent>
        {successMessage ? (
          <div className="mt-4 p-4 bg-green-100 text-green-800 rounded">
            <h2 className="text-xl font-bold">Booking Successful!</h2>
            <p>{successMessage}</p>
          </div>
        ) : (
          <>
            {purpose === "marriage" ? (
              <MarriageForm onSubmit={handleFormSubmit} loading={loading} />
            ) : !clientSecret ? (
              <SpacesForm onSubmit={handleFormSubmit} loading={loading} />
            ) : (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm />
              </Elements>
            )}
          </>
        )}
      </CardContent>
      {error && (
        <div className="mt-4 p-4 bg-red-100 text-red-800 rounded">
          <p>Error: {JSON.stringify(error)}</p>
          <Button onClick={() => dispatch(clearError())}>Dismiss</Button>
        </div>
      )}
    </Card>
  );
}

export default Booking;
