import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "components/shadcn/Card";

const Ceremony = () => {
  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Marriage Ceremony Services</CardTitle>
        <CardDescription>
          Welcome to our Marriage Ceremony Services page. We offer comprehensive
          planning and execution for your special day.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <ul className="space-y-2 list-disc pl-6">
          <li>Venue selection and booking</li>
          <li>Catering arrangements</li>
          <li>Decoration services</li>
          <li>Photography and videography</li>
          <li>Wedding invitation design and distribution</li>
          <li>Coordination of the ceremony program</li>
        </ul>

        <p className="text-sm text-muted-foreground">
          For more information or to book our Marriage Ceremony services, please
          contact us at ceremony@ummapay.com
        </p>
      </CardContent>
    </Card>
  );
};

export default Ceremony;
