import React from "react";

const Funeral = () => {
  return (
    <div>
      <h1>Funeral Services</h1>
      <p>
        Welcome to our Marriage Services page. Here you can find information
        about our marriage-related offerings.
      </p>
      {/* Add more content specific to marriage services */}
    </div>
  );
};

export default Funeral;
