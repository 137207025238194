import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInUp, verifyCode } from "store/authSlice";
import { Card, CardContent } from "components/shadcn/Card";
import { Button } from "components/shadcn/Button";
import { Input } from "components/shadcn/Input";
import { Label } from "components/shadcn/Label";
import { Checkbox } from "components/shadcn/Checkbox";
import loginImage from "assets/auth_image.png";

const Auth = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState("email");
  const [allowPersistentLogin, setAllowPersistentLogin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, loading, error } = useSelector(
    (state) => state.auth,
  );

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard/attendee");
    }
  }, [isAuthenticated, navigate]);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(
      signInUp({ email, allowPersistentLogin }),
    );
    if (signInUp.fulfilled.match(resultAction)) {
      setStep("verification");
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    const resultAction = await dispatch(verifyCode({ email, code }));
    if (verifyCode.fulfilled.match(resultAction)) {
      navigate("/dashboard/attendee");
    }
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <div className="flex min-h-screen">
      <div
        className="flex-1 bg-muted hidden md:flex items-center justify-center"
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      <Card className="flex-1 border-none rounded-none">
        <CardContent className="flex flex-col justify-center h-full p-8 md:p-12 space-y-6">
          <h2 className="text-2xl font-bold">
            {step === "email" ? "Enter your email" : "Enter verification code"}
          </h2>

          {error && <p className="text-destructive text-sm">{error}</p>}

          {step === "email" ? (
            <form onSubmit={handleEmailSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div className="flex items-center space-x-2">
                <Checkbox
                  id="allowPersistentLogin"
                  checked={allowPersistentLogin}
                  onCheckedChange={setAllowPersistentLogin}
                />
                <Label htmlFor="allowPersistentLogin">
                  Allow authentication cookie
                </Label>
              </div>

              <Button type="submit" className="w-full" disabled={loading}>
                {loading ? "Sending..." : "Send Verification Code"}
              </Button>
            </form>
          ) : (
            <form onSubmit={handleVerificationSubmit} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="verificationCode">Verification Code</Label>
                <Input
                  type="text"
                  id="verificationCode"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </div>

              <Button type="submit" className="w-full" disabled={loading}>
                {loading ? "Verifying..." : "Verify"}
              </Button>
            </form>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Auth;
