import axios from "axios";

const API_BASE_URL = "https://api.aladhan.com/v1";

export const getPrayerTimes = async (year, month, city, country, method) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/calendarByCity/${year}/${month}`,
      {
        params: {
          city,
          country,
          method,
        },
      },
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching prayer times:", error);
    return [];
  }
};

export const calculationMethods = [
  { id: 0, name: "Shia Ithna-Ashari" },
  { id: 1, name: "University of Islamic Sciences, Karachi" },
  { id: 2, name: "Islamic Society of North America (ISNA)" },
  { id: 3, name: "Muslim World League" },
  { id: 4, name: "Umm Al-Qura University, Makkah" },
  { id: 5, name: "Egyptian General Authority of Survey" },
  { id: 7, name: "Institute of Geophysics, University of Tehran" },
  { id: 8, name: "Gulf Region" },
  { id: 9, name: "Kuwait" },
  { id: 10, name: "Qatar" },
  { id: 11, name: "Majlis Ugama Islam Singapura, Singapore" },
  { id: 12, name: "Union Organization Islamic de France" },
  { id: 13, name: "Diyanet İşleri Başkanlığı, Turkey" },
  { id: 14, name: "Spiritual Administration of Muslims of Russia" },
];
