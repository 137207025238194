import React from "react";

const Language = () => {
  return (
    <div>
      <h1>Language</h1>
      <p>Language.</p>
      {/* Add more content specific to Tahfizul Qur'an */}
    </div>
  );
};

export default Language;
