import axios from "axios";

const API_URL =
  "https://de73ab92-b4cd-4138-b8d1-e06835111c6c-00-qeiogag3ocfm.picard.replit.dev/v1";

const createPaymentIntent = async (payload) => {
  const response = await axios.post(
    `${API_URL}/payments/create-payment-intent-booking`,
    payload,
  );
  return response.data;
};

const createBooking = async (payload) => {
  const response = await axios.post(`${API_URL}/bookings/create`, payload);
  return response.data;
};

const getOpenBookingSlots = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URL}/booking_slots/get_open_booking_slots`,
      formData,
    );
    console.log("Booking slots data received:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error in getOpenBookingSlots:", error);
    throw error;
  }
};

const getResources = async (mosqueId) => {
  try {
    const response = await axios.post(`${API_URL}/resources/get_resources`, {
      mosque_id: mosqueId,
    });
    return response.data.resources;
  } catch (error) {
    console.error("Error in getResources:", error);
    throw error;
  }
};

const updateBookingSlotStatus = async (bookingSlotIds, status) => {
  try {
    const ids = Array.isArray(bookingSlotIds)
      ? bookingSlotIds
      : [bookingSlotIds];

    const response = await axios.put(
      `${API_URL}/bookings_slots/update_booking_slot_status`,
      {
        booking_slot_ids: ids,
        status: status,
      },
    );
    console.log(`Slots ${status}:`, ids);
    return response.data;
  } catch (error) {
    console.error(`Error updating booking slot status to ${status}:`, error);
    throw error;
  }
};

const bookingAPI = {
  createPaymentIntent,
  createBooking,
  getOpenBookingSlots,
  getResources,
  updateBookingSlotStatus,
};

export default bookingAPI;
