"use client";

import React from "react";
import { cn } from "components/shadcn/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "components/shadcn/NavigationMenu";
import { Button } from "components/shadcn/Button";
import Logo from "assets/logo.webp";
import {
  Settings,
  CreditCard,
  Image as ImageIcon,
  Star,
  Calendar,
  Info,
  Edit3,
  Map,
  LifeBuoy,
  Layout,
  Tag,
  Phone,
  LogIn,
} from "lucide-react";
import MobileNavigation from "./MobileNavigation"; // Import the new MobileNavigation component

const Header = () => {
  return (
    <header className="bg-transparent fixed top-0 left-0 right-0 z-50 flex justify-between items-center p-4 md:justify-center">
      <div className="bg-white shadow-md rounded-lg p-2 md:p-4 md:rounded-lg md:flex md:items-center md:justify-between md:max-w-screen-lg md:w-full md:mx-4">
        <a href="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-8 w-auto" />
        </a>

        <NavigationMenu className="hidden md:flex space-x-4">
          <NavigationMenuList className="flex items-center space-x-4">
            <NavigationMenuItem>
              <NavigationMenuTrigger className="hover:bg-gray-100 px-3 py-2 rounded-md">
                Mosques
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid gap-3 p-6 md:w-[300px] lg:w-[400px]">
                  <ListItem
                    href="/mosques/administration"
                    title="Administration"
                    icon={
                      <Settings className="h-6 w-6 mr-2" aria-hidden="true" />
                    }
                  >
                    Keep track of everything happening in your mosque.
                  </ListItem>
                  <ListItem
                    href="/mosques/payments"
                    title="Payments"
                    icon={
                      <CreditCard className="h-6 w-6 mr-2" aria-hidden="true" />
                    }
                  >
                    Manage every one-off payment, subscription, forms, and
                    scheduling.
                  </ListItem>
                  <ListItem
                    href="/mosques/media-management"
                    title="Media Management"
                    icon={
                      <ImageIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                    }
                  >
                    Manage all your media operations from a single place.
                  </ListItem>
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <NavigationMenuTrigger className="hover:bg-gray-100 px-3 py-2 rounded-md">
                Users
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid gap-3 p-6 md:w-[300px] lg:w-[400px]">
                  <ListItem
                    href="/users/subscription"
                    title="Subscription"
                    icon={<Star className="h-6 w-6 mr-2" aria-hidden="true" />}
                  >
                    Subscribe monthly to your local mosque.
                  </ListItem>
                  <ListItem
                    href="/users/service-bookings"
                    title="Service Bookings"
                    icon={
                      <Calendar className="h-6 w-6 mr-2" aria-hidden="true" />
                    }
                  >
                    Get access and book all your mosque services immediately.
                  </ListItem>
                  <ListItem
                    href="/users/stay-connected"
                    title="Stay Connected"
                    icon={
                      <ImageIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                    }
                  >
                    Get updates for upcoming events, mosque news, lectures and
                    more.
                  </ListItem>
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <NavigationMenuTrigger className="hover:bg-gray-100 px-3 py-2 rounded-md">
                Resources
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <ul className="grid gap-3 p-6 md:w-[300px] lg:w-[400px]">
                  <ListItem
                    href="/resources/about-us"
                    title="About Us"
                    icon={<Info className="h-6 w-6 mr-2" aria-hidden="true" />}
                  >
                    Learn more about our mission and values.
                  </ListItem>
                  <ListItem
                    href="/resources/blog"
                    title="Blog"
                    icon={<Edit3 className="h-6 w-6 mr-2" aria-hidden="true" />}
                  >
                    Read our latest articles and updates.
                  </ListItem>
                  <ListItem
                    href="/resources/roadmaps"
                    title="Roadmaps"
                    icon={<Map className="h-6 w-6 mr-2" aria-hidden="true" />}
                  >
                    Explore our project roadmaps.
                  </ListItem>
                  <ListItem
                    href="/resources/support"
                    title="Support"
                    icon={
                      <LifeBuoy className="h-6 w-6 mr-2" aria-hidden="true" />
                    }
                  >
                    Payment Integration, Help Center, Contact Support, FAQs
                  </ListItem>
                  <ListItem
                    href="/dashboard/attendee"
                    title="Dashboard"
                    icon={
                      <Layout className="h-6 w-6 mr-2" aria-hidden="true" />
                    }
                  >
                    Access your dashboard and manage settings.
                  </ListItem>
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <a
                href="/pricing"
                className="hover:bg-gray-100 px-3 py-2 rounded-md flex items-center"
              >
                <Tag className="h-6 w-6 mr-2" aria-hidden="true" />
                Pricing
              </a>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>

        <div className="hidden md:flex items-center space-x-4">
          <Button
            variant="outline"
            className="flex items-center px-4 py-2 rounded-md shadow-sm hover:bg-gray-100"
          >
            <Phone className="h-6 w-6 mr-2" aria-hidden="true" />
            Contact Sales
          </Button>
          <Button className="flex items-center px-4 py-2 rounded-md bg-black text-white shadow-sm hover:bg-gray-800">
            <LogIn className="h-6 w-6 mr-2" aria-hidden="true" />
            Sign In
          </Button>
        </div>
      </div>

      {/* Include the MobileNavigation component */}
      <MobileNavigation />
    </header>
  );
};

export default Header;

// ListItem Component
const ListItem = React.forwardRef(
  ({ className, title, children, href, icon }, ref) => (
    <li>
      <a
        href={href}
        ref={ref}
        className={cn(
          "flex items-center rounded-md p-3 hover:bg-gray-100",
          className,
        )}
      >
        {icon}
        <div>
          <div className="text-sm font-medium">{title}</div>
          <p className="mt-1 text-sm text-gray-600">{children}</p>
        </div>
      </a>
    </li>
  ),
);
ListItem.displayName = "ListItem";
