import React from "react";
import { Route } from "react-router-dom";
import Landing from "pages/landing/Landing";
import Auth from "pages/auth/Auth";
import PrivacyPolicy from "pages/policies/PrivacyPolicy";
import TermsOfService from "pages/policies/TermsOfService";
import Archive from "pages/archive/Archive";
import Booking from "booking/Booking";

const PublicRoutes = [
  <Route key="landing" path="/" element={<Landing />} />,
  <Route key="auth" path="/auth" element={<Auth />} />,
  <Route
    key="privacy-policy"
    path="/privacy-policy"
    element={<PrivacyPolicy />}
  />,
  <Route
    key="terms-of-service"
    path="/terms-of-service"
    element={<TermsOfService />}
  />,
  <Route
    key="archive-full-access"
    path="/archive/:mosque_glide_id/:token"
    element={<Archive />}
  />,
  <Route key="booking" path="/booking" element={<Booking />} />,
];

export default PublicRoutes;
