import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import {
  Folder,
  File,
  MoreVertical,
  Upload,
  FolderPlus,
  X,
  Download,
  Share,
  Search,
  ChevronUp,
  ChevronDown,
} from "lucide-react";

import { Button } from "components/shadcn/Button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
} from "components/shadcn/Breadcrumb";
import { Card, CardContent, CardFooter } from "components/shadcn/Card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/shadcn/DropdownMenu";
import { Input } from "components/shadcn/Input";
import { Label } from "components/shadcn/Label";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/shadcn/Dialog";
import { Separator } from "components/shadcn/Separator";

import {
  setMosqueGlideId,
  listArchive,
  uploadFile,
  createFolder,
  renameFile,
  renameFolder,
  deleteFile,
  deleteFolder,
  setCurrentFolderPath,
  goToParentDirectory,
  openFilePreview,
  closeFilePreview,
  getDownloadUrl,
  searchArchive,
  navigateToPath, // Import the new thunk
} from "store/archiveSlice";
import { getFileTypeAndIcon, formatFileSize } from "utils/file_utils";

export default function Archive() {
  const dispatch = useDispatch();
  const { mosque_glide_id, token } = useParams();
  const location = useLocation();
  const isAdmin = token === "123admin";
  const isUser = token === "123user";
  const {
    archiveData,
    searchResults,
    loading,
    error,
    currentFolderPath,
    isPreviewOpen,
    previewFileUrl,
    previewFileName,
    previewFileType,
  } = useSelector((state) => state.archive);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [newFolderDialogOpen, setNewFolderDialogOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [previousFolderPath, setPreviousFolderPath] = useState("");

  useEffect(() => {
    if (mosque_glide_id) {
      dispatch(setMosqueGlideId(mosque_glide_id));
      clearSearch();
    }
  }, [dispatch, mosque_glide_id]);

  const pathSegments =
    currentFolderPath === "" ? [] : currentFolderPath.split("/");

  const handlePathClick = (index) => {
    const newPath = pathSegments.slice(0, index + 1).join("/");
    dispatch(navigateToPath(newPath)); // Use the navigateToPath thunk
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("folder_path", currentFolderPath);
      dispatch(uploadFile(formData));
      setUploadDialogOpen(false);
    }
  };

  const handleCreateFolder = () => {
    if (newFolderName) {
      dispatch(
        createFolder({
          folderPath: currentFolderPath,
          folderName: newFolderName,
        }),
      );
      setNewFolderName("");
      setNewFolderDialogOpen(false);
    }
  };

  const handleFolderDoubleClick = (folderName) => {
    const newPath =
      currentFolderPath === ""
        ? folderName
        : `${currentFolderPath}/${folderName}`;
    dispatch(navigateToPath(newPath)); // Use navigateToPath
  };

  const handleFileDoubleClick = async (fileName) => {
    try {
      const result = await dispatch(
        getDownloadUrl({ folderPath: currentFolderPath, fileName }),
      ).unwrap();
      const { download_url } = result || {};
      if (download_url) {
        const { type } = getFileTypeAndIcon(fileName);
        dispatch(
          openFilePreview({
            fileUrl: download_url,
            fileName,
            fileType: type,
          }),
        );
      }
    } catch (error) {
      console.error("Failed to get download URL:", error);
    }
  };

  const handleDownloadFile = async (fileName) => {
    try {
      const result = await dispatch(
        getDownloadUrl({ folderPath: currentFolderPath, fileName }),
      ).unwrap();
      const { download_url } = result || {};
      if (download_url) {
        window.open(download_url, "_blank");
      }
    } catch (error) {
      console.error("Failed to get download URL:", error);
    }
  };

  const handleClosePreview = () => {
    dispatch(closeFilePreview());
    dispatch(listArchive({ folderPath: currentFolderPath }));
  };

  const truncateFileName = (fileName) => {
    if (fileName.length <= 20) return fileName;
    const ext = fileName.split(".").pop();
    const name = fileName.substring(0, fileName.length - ext.length - 1);
    return `${name.substring(0, 10)}...${name.slice(-10)}.${ext}`;
  };

  const handleSearch = () => {
    if (searchQuery.trim()) {
      setPreviousFolderPath(currentFolderPath);
      dispatch(searchArchive({ searchText: searchQuery }));
    } else {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
    dispatch(listArchive({ folderPath: currentFolderPath }));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const sortedItems = useMemo(() => {
    const items = searchResults || archiveData;
    if (!items) return [];

    const folders = [...(items.folders || [])];
    const files = [...(items.files || [])];

    folders.sort((a, b) => a.localeCompare(b) * (sortAscending ? 1 : -1));
    files.sort((a, b) => {
      const nameA = typeof a === "string" ? a : a.name;
      const nameB = typeof b === "string" ? b : b.name;
      return nameA.localeCompare(nameB) * (sortAscending ? 1 : -1);
    });

    return [
      ...folders.map((folder) => ({
        type: "folder",
        name:
          typeof folder === "string"
            ? folder.split("/").pop() || folder
            : folder.name,
        path: folder,
      })),
      ...files.map((file) => ({
        type: "file",
        name: typeof file === "string" ? file.split("/").pop() : file.name,
        path: file,
      })),
    ];
  }, [searchResults, archiveData, sortAscending]);

  const renderPreviewFile = () => {
    if (!isPreviewOpen) return null;

    let PreviewComponent;
    switch (previewFileType) {
      case "image":
        PreviewComponent = (
          <img
            src={previewFileUrl}
            alt={previewFileName}
            className="max-w-full max-h-full object-contain"
          />
        );
        break;
      case "video":
        PreviewComponent = (
          <video controls className="max-w-full max-h-full">
            <source src={previewFileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
        break;
      case "audio":
        PreviewComponent = (
          <audio controls className="w-full">
            <source src={previewFileUrl} />
            Your browser does not support the audio element.
          </audio>
        );
        break;
      default:
        PreviewComponent = (
          <p className="text-center">File type not supported for preview.</p>
        );
    }

    return (
      <div className="fixed inset-0 bg-background z-50 flex flex-col">
        <header className="flex items-center justify-between p-4 border-b">
          <div className="flex items-center space-x-4">
            <Button variant="ghost" size="icon" onClick={handleClosePreview}>
              <X className="h-6 w-6" />
            </Button>
            <h2 className="text-lg font-semibold">
              Mosquely / {previewFileName.split(".").slice(0, -1).join(".")}
            </h2>
            <span className="text-sm text-gray-500 uppercase">
              {previewFileType}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <Button onClick={() => handleDownloadFile(previewFileName)}>
              <Download className="mr-2 h-4 w-4" />
              Download
            </Button>
            <Button>
              <Share className="mr-2 h-4 w-4" />
              Share
            </Button>
          </div>
        </header>
        <main className="flex-1 overflow-auto p-4 flex items-center justify-center">
          {PreviewComponent}
        </main>
      </div>
    );
  };

  return (
    <div className="flex h-screen flex-col bg-background">
      <header className="flex flex-col gap-4 border-b bg-background p-6">
        <div className="flex items-center justify-between">
          <div className="relative w-64">
            <Input
              type="search"
              placeholder="Search files and folders..."
              className="pr-20"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            {searchQuery && (
              <Button
                className="absolute right-8 top-0 bottom-0"
                onClick={clearSearch}
                variant="ghost"
              >
                <X className="h-4 w-4" />
              </Button>
            )}
            <Button
              className="absolute right-0 top-0 bottom-0"
              onClick={handleSearch}
              variant="ghost"
            >
              <Search className="h-4 w-4" />
            </Button>
          </div>
          <div className="flex items-end gap-4">
            <Button
              variant="outline"
              size="sm"
              className="flex flex-col items-center p-2 h-auto"
              onClick={() => setUploadDialogOpen(true)}
            >
              <Upload className="h-4 w-4 mb-1" />
              <span className="text-xs">Upload</span>
            </Button>
            <Button
              variant="outline"
              size="sm"
              className="flex flex-col items-center p-2 h-auto"
              onClick={() => setNewFolderDialogOpen(true)}
            >
              <FolderPlus className="h-4 w-4 mb-1" />
              <span className="text-xs">Create Folder</span>
            </Button>
          </div>
        </div>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink
                onClick={() => {
                  dispatch(navigateToPath("")); // Navigate to root
                  clearSearch();
                }}
              >
                All Files
              </BreadcrumbLink>
            </BreadcrumbItem>
            {pathSegments.map((folder, index) => (
              <React.Fragment key={index}>
                <BreadcrumbItem>/</BreadcrumbItem>
                <BreadcrumbItem>
                  {index === pathSegments.length - 1 ? (
                    <BreadcrumbPage>{folder}</BreadcrumbPage>
                  ) : (
                    <BreadcrumbLink onClick={() => handlePathClick(index)}>
                      {folder}
                    </BreadcrumbLink>
                  )}
                </BreadcrumbItem>
              </React.Fragment>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex items-center">
          <span className="mr-2 font-semibold">Name</span>
          <Button
            variant="ghost"
            size="sm"
            onClick={() => setSortAscending(!sortAscending)}
          >
            {sortAscending ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </Button>
        </div>
      </header>

      <Separator />

      <main className="flex-1 overflow-auto p-6">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <p>Loading...</p>
          </div>
        ) : error ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-destructive">Error: {error}</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {sortedItems.map((item, index) => (
              <Card
                key={index}
                className="cursor-pointer transition-colors hover:bg-muted/50 group"
                onDoubleClick={() =>
                  item.type === "folder"
                    ? handleFolderDoubleClick(item.path)
                    : handleFileDoubleClick(item.name)
                }
              >
                <CardContent className="p-0">
                  <div className="bg-[#F6F5F2] aspect-square flex items-center justify-center relative">
                    {item.type === "folder" ? (
                      <Folder
                        className="h-24 w-24"
                        style={{ color: "#5A8663" }}
                      />
                    ) : (
                      <>
                        {getFileTypeAndIcon(item.name).icon ? (
                          <img
                            src={getFileTypeAndIcon(item.name).icon}
                            alt={getFileTypeAndIcon(item.name).type}
                            className="h-24 w-24"
                          />
                        ) : (
                          <File
                            className="h-24 w-24"
                            style={{ color: "#5A8663" }}
                          />
                        )}
                      </>
                    )}
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          size="icon"
                          className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <MoreVertical className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        {item.type === "folder" ? (
                          <>
                            <DropdownMenuItem
                              onClick={() => handleFolderDoubleClick(item.path)}
                            >
                              Open
                            </DropdownMenuItem>

                            {isAdmin && (
                              <>
                                <DropdownMenuItem
                                  onClick={() =>
                                    dispatch(
                                      renameFolder({
                                        folderPath: currentFolderPath,
                                        oldName: item.name,
                                        newName: "New Name",
                                      }),
                                    )
                                  }
                                >
                                  Rename
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  onClick={() =>
                                    dispatch(
                                      deleteFolder({
                                        folderPath: currentFolderPath,
                                        folderName: item.name,
                                      }),
                                    )
                                  }
                                >
                                  Delete
                                </DropdownMenuItem>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <DropdownMenuItem
                              onClick={() => handleFileDoubleClick(item.name)}
                            >
                              Preview
                            </DropdownMenuItem>
                            <DropdownMenuItem
                              onClick={() => handleDownloadFile(item.name)}
                            >
                              Download
                            </DropdownMenuItem>
                            {isAdmin && (
                              <>
                                <DropdownMenuItem
                                  onClick={() =>
                                    dispatch(
                                      renameFile({
                                        folderPath: currentFolderPath,
                                        oldName: item.name,
                                        newName: "New Name",
                                      }),
                                    )
                                  }
                                >
                                  Rename
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  onClick={() =>
                                    dispatch(
                                      deleteFile({
                                        folderPath: currentFolderPath,
                                        fileName: item.name,
                                      }),
                                    )
                                  }
                                >
                                  Delete
                                </DropdownMenuItem>
                              </>
                            )}
                          </>
                        )}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </CardContent>
                <CardFooter className="p-2">
                  <div className="w-full">
                    <p className="text-sm font-medium leading-none truncate">
                      {truncateFileName(item.name)}
                    </p>
                    <p className="text-xs text-muted-foreground mt-1">
                      {item.type === "folder"
                        ? "Folder"
                        : `${getFileTypeAndIcon(item.name).type}`}
                    </p>
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>
        )}
      </main>

      {renderPreviewFile()}

      <Dialog open={uploadDialogOpen} onOpenChange={setUploadDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Upload File</DialogTitle>
            <DialogDescription>
              Choose a file to upload to the current folder
            </DialogDescription>
          </DialogHeader>
          <div className="grid w-full max-w-sm items-center gap-1.5">
            <Label htmlFor="file">File</Label>
            <Input id="file" type="file" onChange={handleFileUpload} />
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={newFolderDialogOpen} onOpenChange={setNewFolderDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create New Folder</DialogTitle>
            <DialogDescription>
              Enter a name for the new folder
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name
              </Label>
              <Input
                id="name"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                className="col-span-3"
              />
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleCreateFolder}>Create folder</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
