import React, { useState, useEffect } from "react";
import { getPrayerTimes } from "api/prayerTimesAPI";
import { Card, CardContent } from "components/shadcn/Card";
import fajrImage from "assets/prayer-times/fajr.jpeg";
import sunriseImage from "assets/prayer-times/sunrise.jpeg";
import dhuhrImage from "assets/prayer-times/dhur.jpeg";
import asrImage from "assets/prayer-times/asr.jpeg";
import maghribImage from "assets/prayer-times/maghrib.jpeg";
import ishaImage from "assets/prayer-times/isha.jpeg";

const PrayerTimes = () => {
  const [prayerData, setPrayerData] = useState(null);
  const [activePrayer, setActivePrayer] = useState("");
  const [nextPrayer, setNextPrayer] = useState("");
  const [timeUntilNextPrayer, setTimeUntilNextPrayer] = useState("");

  useEffect(() => {
    const fetchPrayerTimes = async () => {
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const times = await getPrayerTimes(year, month, "London", "UK", 2);
      const todayData = times[day - 1];
      setPrayerData(todayData);
    };

    fetchPrayerTimes();
  }, []);

  useEffect(() => {
    const updatePrayerStatus = () => {
      if (!prayerData) return;

      const now = new Date();
      const currentTime = now.getHours() * 60 + now.getMinutes();

      const prayers = [
        { name: "Fajr", time: prayerData.timings.Fajr },
        { name: "Sunrise", time: prayerData.timings.Sunrise },
        { name: "Dhuhr", time: prayerData.timings.Dhuhr },
        { name: "Asr", time: prayerData.timings.Asr },
        { name: "Maghrib", time: prayerData.timings.Maghrib },
        { name: "Isha", time: prayerData.timings.Isha },
      ];

      let activeIndex = -1;
      for (let i = prayers.length - 1; i >= 0; i--) {
        const prayerTime = prayers[i].time.split(":");
        const prayerMinutes =
          parseInt(prayerTime[0]) * 60 + parseInt(prayerTime[1]);

        if (currentTime >= prayerMinutes) {
          setActivePrayer(prayers[i].name);
          activeIndex = i;
          break;
        }
      }

      const nextIndex = (activeIndex + 1) % prayers.length;
      setNextPrayer(prayers[nextIndex].name);

      let nextPrayerTime = prayers[nextIndex].time.split(":");
      let nextPrayerMinutes =
        parseInt(nextPrayerTime[0]) * 60 + parseInt(nextPrayerTime[1]);

      // If next prayer is Fajr and it's after Isha, add 24 hours
      if (nextIndex === 0 && activeIndex === prayers.length - 1) {
        nextPrayerMinutes += 24 * 60;
      }

      let minutesUntilNextPrayer = nextPrayerMinutes - currentTime;
      if (minutesUntilNextPrayer < 0) {
        minutesUntilNextPrayer += 24 * 60; // Add 24 hours if negative
      }

      const hoursUntilNextPrayer = Math.floor(minutesUntilNextPrayer / 60);
      const remainingMinutes = minutesUntilNextPrayer % 60;

      // Format the time string
      let timeString = "";
      if (hoursUntilNextPrayer > 0) {
        timeString += `${hoursUntilNextPrayer}h `;
      }
      timeString += `${remainingMinutes}m`;

      setTimeUntilNextPrayer(timeString);
    };

    updatePrayerStatus();
    const interval = setInterval(updatePrayerStatus, 60000);

    return () => clearInterval(interval);
  }, [prayerData]);

  if (!prayerData) {
    return <div className="text-center py-4">Loading prayer times...</div>;
  }

  const prayersList = [
    { name: "Fajr", time: prayerData.timings.Fajr, image: fajrImage },
    { name: "Sunrise", time: prayerData.timings.Sunrise, image: sunriseImage },
    { name: "Dhuhr", time: prayerData.timings.Dhuhr, image: dhuhrImage },
    { name: "Asr", time: prayerData.timings.Asr, image: asrImage },
    { name: "Maghrib", time: prayerData.timings.Maghrib, image: maghribImage },
    { name: "Isha", time: prayerData.timings.Isha, image: ishaImage },
  ];

  const islamicDate = `${prayerData.date.hijri.day} ${prayerData.date.hijri.month.en} ${prayerData.date.hijri.year} AH`;
  const gregorianDate = `${prayerData.date.gregorian.day} ${prayerData.date.gregorian.month.en} ${prayerData.date.gregorian.year}`;

  return (
    <div className="w-full space-y-4">
      <Card className="bg-blue-50/10">
        <CardContent className="flex justify-between items-center p-4">
          <div className="flex flex-col">
            <span className="text-sm font-semibold text-gray-600">
              {islamicDate}
            </span>
            <span className="text-sm text-gray-500">{gregorianDate}</span>
          </div>
          <div className="text-right">
            <div className="text-sm font-semibold">Next: {nextPrayer}</div>
            <div className="text-sm">In: {timeUntilNextPrayer}</div>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-3 md:grid-cols-6 gap-2">
        {prayersList.map((prayer) => (
          <Card
            key={prayer.name}
            className={`transition-colors duration-200 ${
              activePrayer === prayer.name ? "bg-orange-50" : ""
            }`}
          >
            <CardContent className="flex flex-col items-center p-3 space-y-2">
              <div className="w-10 h-10 md:w-12 md:h-12 rounded-full overflow-hidden">
                <img
                  src={prayer.image}
                  alt={prayer.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <span className="text-xs md:text-sm font-medium">
                {prayer.name}
              </span>
              <span className="text-xs text-gray-500">
                {prayer.time.slice(0, 5)}
              </span>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default PrayerTimes;
