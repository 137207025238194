import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bookingAPI from "api/bookingAPI";
import { MOCK_BOOKING_DATA } from "../booking/calendar/utils";

// Async thunks
export const createPaymentIntent = createAsyncThunk(
  "booking/createPaymentIntent",
  async (payload, { rejectWithValue }) => {
    try {
      return await bookingAPI.createPaymentIntent(payload);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const createBooking = createAsyncThunk(
  "booking/createBooking",
  async (payload, { rejectWithValue }) => {
    try {
      return await bookingAPI.createBooking(payload);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchBookingSlots = createAsyncThunk(
  "booking/fetchBookingSlots",
  async (payload, { rejectWithValue }) => {
    try {
      return await bookingAPI.getOpenBookingSlots(payload);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const fetchResources = createAsyncThunk(
  "booking/fetchResources",
  async (mosqueId, { rejectWithValue }) => {
    try {
      return await bookingAPI.getResources(mosqueId);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

const initialState = {
  bookingId: "",
  clientSecret: "",
  loading: false,
  error: null,
  successMessage: "",
  calendar: {
    currentDate: new Date().toISOString(),
    selectedDate: null,
    selectedSlotId: null,
    bookingSlots: MOCK_BOOKING_DATA.booking_slots,
    // Store as array instead of Set for serialization
    daysWithSlots: Object.keys(MOCK_BOOKING_DATA.booking_slots),
    resources: [],
    selectedResourceId: null,
    selectedSpaceIds: [],
    isLoading: false,
    error: null,
  },
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
      state.calendar.error = null;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = "";
    },
    updatePaymentSuccess: (state, action) => {
      state.successMessage = action.payload;
    },
    setCurrentDate: (state, action) => {
      const dateValue =
        action.payload instanceof Date
          ? action.payload.toISOString()
          : action.payload;
      state.calendar.currentDate = dateValue;
    },
    setSelectedDate: (state, action) => {
      const dateValue =
        action.payload instanceof Date
          ? action.payload.toISOString()
          : action.payload;
      state.calendar.selectedDate = dateValue;
      state.calendar.selectedSlotId = null;
    },
    setSelectedSlotId: (state, action) => {
      state.calendar.selectedSlotId = action.payload;
    },
    setSelectedResource: (state, action) => {
      state.calendar.selectedResourceId = action.payload;
      state.calendar.selectedSpaceIds = [];
    },
    setSelectedSpaces: (state, action) => {
      state.calendar.selectedSpaceIds = action.payload;
    },
    setBookingSlots: (state, action) => {
      state.calendar.bookingSlots = action.payload;
      // Store as array instead of Set
      state.calendar.daysWithSlots = Object.keys(action.payload);
    },
    setLoading: (state, action) => {
      state.calendar.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.calendar.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Payment Intent cases
      .addCase(createPaymentIntent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPaymentIntent.fulfilled, (state, action) => {
        state.loading = false;
        state.clientSecret = action.payload.clientSecret;
      })
      .addCase(createPaymentIntent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Create Booking cases
      .addCase(createBooking.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingId = action.payload.bookingId;
      })
      .addCase(createBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Booking Slots cases
      .addCase(fetchBookingSlots.pending, (state) => {
        state.calendar.isLoading = true;
        state.calendar.error = null;
      })
      .addCase(fetchBookingSlots.fulfilled, (state, action) => {
        state.calendar.isLoading = false;
        state.calendar.bookingSlots = action.payload.booking_slots;
        // Store as array instead of Set
        state.calendar.daysWithSlots = Object.keys(
          action.payload.booking_slots,
        );
      })
      .addCase(fetchBookingSlots.rejected, (state, action) => {
        state.calendar.isLoading = false;
        state.calendar.error = action.payload;
      })
      // Resources cases
      .addCase(fetchResources.pending, (state) => {
        state.calendar.isLoading = true;
        state.calendar.error = null;
      })
      .addCase(fetchResources.fulfilled, (state, action) => {
        state.calendar.isLoading = false;
        state.calendar.resources = action.payload;
      })
      .addCase(fetchResources.rejected, (state, action) => {
        state.calendar.isLoading = false;
        state.calendar.error = action.payload;
      });
  },
});

export const {
  clearError,
  clearSuccessMessage,
  updatePaymentSuccess,
  setCurrentDate,
  setSelectedDate,
  setSelectedSlotId,
  setSelectedResource,
  setSelectedSpaces,
  setBookingSlots,
  setLoading,
  setError,
} = bookingSlice.actions;

export default bookingSlice.reducer;
