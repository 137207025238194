import React, { useState, useRef, useEffect } from "react";
import { Button } from "components/shadcn/Button";
import { Input } from "components/shadcn/Input";
import { Card } from "components/shadcn/Card";
import { ScrollArea } from "components/shadcn/ScrollArea";
import chatIllustration from "assets/chat_background_image.png";

const AskImaam = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const messageContainerRef = useRef(null);

  const sampleQuestions = [
    "Prayer conditions?",
    "How to perform Ghusl?",
    "Missed prayers ruling",
    "Explain Zakat",
    "Ramadan fasting etiquettes",
    "Dealing with waswas in prayer",
  ];

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim()) {
      const userMessage = {
        id: messages.length + 1,
        text: newMessage,
        sender: "user",
        timestamp: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      };
      setMessages([...messages, userMessage]);
      setNewMessage("");
      setSelectedQuestion(null);

      // Simulate Imaam response (replace with actual API call in production)
      setTimeout(() => {
        const imaamResponse = {
          id: messages.length + 2,
          text: "Thank you for your question. I'll do my best to provide a helpful answer.",
          sender: "imaam",
          timestamp: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
        };
        setMessages((prevMessages) => [...prevMessages, imaamResponse]);
      }, 1000);
    }
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setNewMessage(question);
  };

  return (
    <Card className="p-4 max-w-md mx-auto">
      <div className="mb-4 space-y-2">
        {sampleQuestions.map((question, index) => (
          <Button
            key={index}
            variant={selectedQuestion === question ? "outline" : "ghost"}
            onClick={() => handleQuestionClick(question)}
            className="text-sm"
          >
            {question}
          </Button>
        ))}
      </div>
      <Card className="flex flex-col flex-grow bg-muted rounded-lg overflow-hidden">
        <ScrollArea
          className="flex-grow p-4 space-y-4 overflow-y-auto"
          ref={messageContainerRef}
        >
          {messages.length === 0 ? (
            <div className="flex justify-center items-center h-full">
              <img
                src={chatIllustration}
                alt="Chat Illustration"
                className="h-32 w-auto opacity-50"
              />
            </div>
          ) : (
            messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.sender === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`max-w-xs p-3 rounded-lg shadow ${
                    message.sender === "user"
                      ? "bg-primary text-primary-foreground"
                      : "bg-secondary text-secondary-foreground"
                  }`}
                >
                  <p className="mb-1">{message.text}</p>
                  <span className="block text-xs opacity-70 text-right">
                    {message.timestamp}
                  </span>
                </div>
              </div>
            ))
          )}
        </ScrollArea>
        <form
          onSubmit={handleSendMessage}
          className="flex items-center border-t p-2 bg-background"
        >
          <Input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your question or select from above..."
            className="flex-grow mr-2"
          />
          <Button type="submit" variant="primary">
            ➤
          </Button>
        </form>
      </Card>
    </Card>
  );
};

export default AskImaam;
