import React, { useState } from "react";
import { Button } from "components/shadcn/Button";
import { Label } from "components/shadcn/Label";
import { Checkbox } from "components/shadcn/Checkbox";

const SPACES = [
  { name: "Large Hall", id: "44444444-4444-4444-4444-444444444444" },
  { name: "Small Hall", id: "55555555-5555-5555-5555-555555555555" },
  { name: "Kitchen", id: "66666666-6666-6666-6666-666666666666" },
  { name: "Meeting Room", id: "77777777-7777-7777-7777-777777777777" },
];

function SpacesForm({ onSubmit, loading }) {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    purpose_of_hire: "",
    selectedSpaces: [],
    payment_type: "full", // Default to full payment
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (spaceId) => {
    setFormData((prev) => ({
      ...prev,
      selectedSpaces: prev.selectedSpaces.includes(spaceId)
        ? prev.selectedSpaces.filter((id) => id !== spaceId)
        : [...prev.selectedSpaces, spaceId],
    }));
  };

  const calculateTotalCost = () => {
    const costs = {
      "44444444-4444-4444-4444-444444444444": 200, // Large Hall
      "55555555-5555-5555-5555-555555555555": 100, // Small Hall
      "66666666-6666-6666-6666-666666666666": 75, // Kitchen
      "77777777-7777-7777-7777-777777777777": 50, // Meeting Room
    };
    const total = formData.selectedSpaces.reduce(
      (sum, spaceId) => sum + costs[spaceId],
      0,
    );
    return formData.payment_type === "deposit" ? total * 0.5 : total;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData, totalCost: calculateTotalCost() });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="space-y-2">
        <Label htmlFor="name">Name</Label>
        <input
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          className="border rounded w-full p-2"
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="purpose_of_hire">Purpose of Hire</Label>
        <input
          id="purpose_of_hire"
          name="purpose_of_hire"
          value={formData.purpose_of_hire}
          onChange={handleChange}
          required
          className="border rounded w-full p-2"
        />
      </div>

      <div className="space-y-2">
        <Label>Select Spaces</Label>
        {SPACES.map((space) => (
          <div key={space.id} className="flex items-center space-x-2">
            <Checkbox
              id={space.id}
              checked={formData.selectedSpaces.includes(space.id)}
              onCheckedChange={() => handleCheckboxChange(space.id)}
            />
            <Label htmlFor={space.id}>{space.name}</Label>
          </div>
        ))}
      </div>

      <div className="space-y-2">
        <Label>Payment Type</Label>
        <div className="flex space-x-4">
          <Button
            type="button"
            variant={formData.payment_type === "full" ? "default" : "outline"}
            onClick={() =>
              setFormData((prev) => ({ ...prev, payment_type: "full" }))
            }
          >
            Full Payment
          </Button>
          <Button
            type="button"
            variant={
              formData.payment_type === "deposit" ? "default" : "outline"
            }
            onClick={() =>
              setFormData((prev) => ({ ...prev, payment_type: "deposit" }))
            }
          >
            Deposit
          </Button>
        </div>
      </div>

      <div className="text-right font-bold">
        Total Cost: £{calculateTotalCost().toFixed(2)}
      </div>

      <Button type="submit" className="w-full" disabled={loading}>
        {loading ? "Submitting..." : "Submit"}
      </Button>
    </form>
  );
}

export default SpacesForm;
