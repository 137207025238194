import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "components/shadcn/Card";
import CalendarHeader from "./components/CalendarHeader";
import WeekdayHeader from "./components/WeekdayHeader";
import DayGrid from "./components/DayGrid";
import TimeSlots from "./components/TimeSlots";
import { dateUtils } from "./utils";
import {
    setCurrentDate,
    setSelectedDate,
    setSelectedSlotId,
} from "store/bookingSlice";

const Calendar = () => {
    const dispatch = useDispatch();
    const {
        currentDate,
        selectedDate,
        selectedSlotId,
        bookingSlots,
        daysWithSlots,
    } = useSelector((state) => state.booking.calendar);

    const handlePrevMonth = () => {
        dispatch(
            setCurrentDate(
                dateUtils
                    .navigateMonth(new Date(currentDate), -1)
                    .toISOString(),
            ),
        );
    };

    const handleNextMonth = () => {
        dispatch(
            setCurrentDate(
                dateUtils.navigateMonth(new Date(currentDate), 1).toISOString(),
            ),
        );
    };

    const handleSelectDay = (day) => {
        const newSelectedDate = new Date(currentDate);
        newSelectedDate.setDate(day);
        dispatch(setSelectedDate(newSelectedDate.toISOString()));
    };

    const handleSelectSlot = (slot) => {
        dispatch(setSelectedSlotId(slot.booking_slot_id));
    };

    const selectedDateString = selectedDate
        ? new Date(selectedDate).toISOString().split("T")[0]
        : null;
    const slotsForSelectedDate = selectedDateString
        ? bookingSlots[selectedDateString] || []
        : [];

    return (
        <div className="flex flex-col lg:flex-row gap-4">
            <Card className="p-4">
                <CalendarHeader
                    currentDate={new Date(currentDate)}
                    onPrevMonth={handlePrevMonth}
                    onNextMonth={handleNextMonth}
                />
                <WeekdayHeader />
                <DayGrid
                    currentDate={new Date(currentDate)}
                    selectedDate={selectedDate ? new Date(selectedDate) : null}
                    daysWithSlots={daysWithSlots}
                    onSelectDay={handleSelectDay}
                />
            </Card>
            <Card className="p-4 flex-1">
                <TimeSlots
                    slots={slotsForSelectedDate}
                    selectedSlotId={selectedSlotId}
                    onSelectSlot={handleSelectSlot}
                />
            </Card>
        </div>
    );
};

export default Calendar;
