import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoHomeOutline, IoCalendarClearOutline } from "react-icons/io5";
import { HiOutlineNewspaper } from "react-icons/hi2";
import { MdOutlineSubscriptions } from "react-icons/md";
import { TbGridDots } from "react-icons/tb";
import { Button } from "components/shadcn/Button";

const MobileNavigation = () => {
  const location = useLocation();

  const navItems = [
    { to: "/dashboard/admin", icon: IoHomeOutline, label: "Home" },
    { to: "/dashboard/attendee/services", icon: TbGridDots, label: "Services" },
    { to: "/dashboard/attendee/news", icon: HiOutlineNewspaper, label: "News" },
    {
      to: "/dashboard/attendee/events",
      icon: IoCalendarClearOutline,
      label: "Events",
    },
    {
      to: "/dashboard/attendee/subscription",
      icon: MdOutlineSubscriptions,
      label: "Subscription",
    },
  ];

  return (
    <>
      {/* Mobile Navigation */}
      <nav className="fixed bottom-0 left-0 right-0 bg-background border-t md:hidden">
        <div className="grid grid-cols-5 gap-1 p-2">
          {navItems.map(({ to, icon: Icon, label }) => (
            <Link key={to} to={to}>
              <Button
                variant={location.pathname === to ? "default" : "ghost"}
                className="w-full h-full flex flex-col items-center justify-center py-2 px-1 space-y-1"
                size="sm"
              >
                <Icon className="h-5 w-5" />
                <span className="text-xs">{label}</span>
              </Button>
            </Link>
          ))}
        </div>
      </nav>
    </>
  );
};

export default MobileNavigation;
