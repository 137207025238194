import React from "react";
import {
  Card,
  CardContent
} from "components/shadcn/Card";
import marriageIcon from "assets/services/marriage_icon.png";
import graveyardIcon from "assets/services/graveyard_icon.png";
import quranIcon from "assets/services/quran_icon.png";

const Events = () => {
  const upcomingEvents = [
    {
      id: 1,
      title: "Nikah Ceremony",
      date: "Apr 15, 2024",
      location: "Masjid Al-Noor",
      time: "9:00 AM - 11:00 AM",
      icon: marriageIcon,
    },
    {
      id: 2,
      title: "Funeral Prayer",
      date: "Apr 16, 2024",
      location: "Islamic Center",
      time: "2:00 PM - 3:00 PM",
      icon: graveyardIcon,
    },
    {
      id: 3,
      title: "Quran Recitation",
      date: "Apr 20, 2024",
      location: "Community Hall",
      time: "7:00 PM - 9:00 PM",
      icon: quranIcon,
    },
  ];

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold text-green-600 mb-6">
        Upcoming Events
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {upcomingEvents.map((event) => (
          <Card
            key={event.id}
            className="flex flex-row items-center p-4 hover:shadow-md transition-shadow"
          >
            <div className="w-20 h-20 flex-shrink-0 mr-4 rounded-lg bg-white flex items-center justify-center overflow-hidden">
              <img
                src={event.icon}
                alt={event.title}
                className="max-w-full max-h-full object-contain"
              />
            </div>
            <CardContent className="p-0 flex-grow">
              <h3 className="text-lg font-semibold text-gray-800 mb-1">
                {event.title}
              </h3>
              <p className="text-sm text-gray-600 mb-1">{event.date}</p>
              <p className="text-sm text-gray-600 mb-1">{event.location}</p>
              <p className="text-sm font-semibold text-green-600">
                {event.time}
              </p>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Events;
