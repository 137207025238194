import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { saveNikahDetails } from "store/nikahSlice";
import { format } from "date-fns";
import { IoClose } from "react-icons/io5";
import { FaRegCalendarAlt, FaClock } from "react-icons/fa";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
  CardDescription,
} from "components/shadcn/Card";
import { Button } from "components/shadcn/Button";
import { Label } from "components/shadcn/Label";
import { Input } from "components/shadcn/Input";
import { Textarea } from "components/shadcn/Textarea";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "components/shadcn/Dialog";
import { Calendar } from "components/shadcn/Calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/shadcn/Popover";

const Nikah = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    groomName: "",
    brideName: "",
    bookingDate: null,
    bookingTime: "",
    venue: "",
    witnesses: "",
    additionalNotes: "",
  });
  const [showTimePopup, setShowTimePopup] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateSelect = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      bookingDate: date,
    }));
    setShowTimePopup(true);
  };

  const handleTimeSelect = (time) => {
    setFormData((prevState) => ({
      ...prevState,
      bookingTime: time,
    }));
    setShowTimePopup(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(saveNikahDetails(formData));
  };

  const clearBooking = () => {
    setFormData((prevState) => ({
      ...prevState,
      bookingDate: null,
      bookingTime: "",
    }));
  };

  const timeSlots = [
    "9:00 AM",
    "10:00 AM",
    "11:00 AM",
    "12:00 PM",
    "1:00 PM",
    "2:00 PM",
    "3:00 PM",
    "4:00 PM",
  ];

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">Nikah Registration</CardTitle>
        <CardDescription>
          Please fill in the details below to register for your nikah ceremony
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="space-y-4">
            {/* Couple Details Section */}
            <div className="grid gap-4 md:grid-cols-2">
              <div className="space-y-2">
                <Label htmlFor="groomName">Groom's Name</Label>
                <Input
                  id="groomName"
                  name="groomName"
                  placeholder="Enter groom's full name"
                  value={formData.groomName}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="brideName">Bride's Name</Label>
                <Input
                  id="brideName"
                  name="brideName"
                  placeholder="Enter bride's full name"
                  value={formData.brideName}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>

            {/* Date & Time Selection */}
            <div className="space-y-2">
              <Label>Appointment Date & Time</Label>
              {formData.bookingDate && formData.bookingTime ? (
                <div className="flex items-center justify-between p-3 bg-primary/10 rounded-lg border border-primary/20">
                  <div className="flex items-center space-x-4">
                    <FaRegCalendarAlt className="text-primary h-5 w-5" />
                    <span className="font-medium">
                      {format(formData.bookingDate, "PPP")} at{" "}
                      {formData.bookingTime}
                    </span>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={clearBooking}
                    className="text-destructive hover:text-destructive/90"
                  >
                    <IoClose className="h-5 w-5" />
                  </Button>
                </div>
              ) : (
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className="w-full justify-start text-left font-normal"
                    >
                      <FaRegCalendarAlt className="mr-2 h-4 w-4" />
                      {formData.bookingDate ? (
                        format(formData.bookingDate, "PPP")
                      ) : (
                        <span>Pick a date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={formData.bookingDate}
                      onSelect={handleDateSelect}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
              )}
            </div>

            {/* Venue */}
            <div className="space-y-2">
              <Label htmlFor="venue">Venue</Label>
              <Input
                id="venue"
                name="venue"
                placeholder="Enter venue details"
                value={formData.venue}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Witnesses */}
            <div className="space-y-2">
              <Label htmlFor="witnesses">Witnesses</Label>
              <Input
                id="witnesses"
                name="witnesses"
                placeholder="Enter witness names (comma-separated)"
                value={formData.witnesses}
                onChange={handleInputChange}
                required
              />
            </div>

            {/* Additional Notes */}
            <div className="space-y-2">
              <Label htmlFor="additionalNotes">Additional Notes</Label>
              <Textarea
                id="additionalNotes"
                name="additionalNotes"
                placeholder="Any special requirements or additional information"
                value={formData.additionalNotes}
                onChange={handleInputChange}
                className="min-h-[100px]"
              />
            </div>
          </div>

          <Button type="submit" className="w-full">
            Submit Registration
          </Button>
        </form>

        {/* Time Selection Dialog */}
        <Dialog open={showTimePopup} onOpenChange={setShowTimePopup}>
          <DialogContent className="sm:max-w-[425px]">
            <DialogTitle>Select Appointment Time</DialogTitle>
            <DialogDescription>
              Choose a preferred time for your nikah ceremony
            </DialogDescription>
            <div className="grid grid-cols-2 gap-2 pt-4">
              {timeSlots.map((slot) => (
                <Button
                  key={slot}
                  variant="outline"
                  className="flex items-center justify-center gap-2"
                  onClick={() => handleTimeSelect(slot)}
                >
                  <FaClock className="h-4 w-4" />
                  {slot}
                </Button>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default Nikah;
