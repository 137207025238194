import React from "react";

const Health = () => {
  return (
    <div>
      <h1>Health</h1>
      <p>Health.</p>
      {/* Add more content specific to Tahfizul Qur'an */}
    </div>
  );
};

export default Health;
