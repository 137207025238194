import React, { useState, useEffect } from "react";
import { Edit, Trash2, Plus } from "lucide-react";
import { Skeleton } from "components/shadcn/Skeleton";
import { Button } from "components/shadcn/Button";
import { Card } from "components/shadcn/Card";
import { Label } from "components/shadcn/Label";
import { Dialog, DialogTrigger, DialogContent } from "components/shadcn/Dialog";
import { Input } from "components/shadcn/Input";
import { Textarea } from "components/shadcn/Textarea";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "components/shadcn/Select";
import { DatePickerDemo } from "components/shadcn/DatePicker";

const TimePicker = ({ value, onChange }) => {
  return (
    <Input
      type="time"
      value={value}
      onChange={onChange}
      className="w-full"
    />
  );
};

const Home = () => {
  const [resources, setResources] = useState([]);
  const [isLoadingResources, setIsLoadingResources] = useState(false);
  const [mosqueId, setMosqueId] = useState("11111111-1111-1111-1111-111111111111");
  const [mosqueGlideId, setMosqueGlideId] = useState("phUqPI4hSaW2tzhP48FSpw");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const BASE_URL_RESOURCES = "https://api.mosquely.com/v1/resources";
  const BASE_URL_BOOKING_SLOTS = "https://api.mosquely.com/v1/booking_slots";

  useEffect(() => {
    const fetchResources = async () => {
      try {
        setIsLoadingResources(true);
        const resourcesData = await getResources(mosqueId, mosqueGlideId);
        setResources(resourcesData);
      } catch (error) {
        console.error('Error fetching resources:', error);
        setErrorMessage("Failed to fetch resources.");
      } finally {
        setIsLoadingResources(false);
      }
    };
    fetchResources();
  }, [mosqueId, mosqueGlideId]);

  const getResources = async (mosqueId, mosqueGlideId) => {
    try {
      const response = await fetch(`${BASE_URL_RESOURCES}/get_resources`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          mosque_id: mosqueId,
          mosque_glide_id: mosqueGlideId,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch resources');
      }

      const data = await response.json();
      return data.resources;
    } catch (error) {
      console.error('Error in getResources:', error);
      throw error;
    }
  };

  const handleUpdateResource = async (resourceId, name, description) => {
    try {
      const response = await fetch(`${BASE_URL_RESOURCES}/update_resource`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          resource_id: resourceId,
          name,
          description,
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to update resource');
      }

      setSuccessMessage('Resource updated successfully.');
      // Refresh resources
      const resourcesData = await getResources(mosqueId, mosqueGlideId);
      setResources(resourcesData);
    } catch (error) {
      console.error('Error in handleUpdateResource:', error);
      setErrorMessage(error.message);
    }
  };

  const handleDeleteResource = async (resourceId) => {
    // Implement resource deletion logic here
    // You can set success or error messages similarly
  };

  const handleUpdateSpace = async (spaceId, name, description) => {
    try {
      const response = await fetch(`${BASE_URL_RESOURCES}/update_space`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          space_id: spaceId,
          name,
          description,
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to update space');
      }

      setSuccessMessage('Space updated successfully.');
      // Refresh resources
      const resourcesData = await getResources(mosqueId, mosqueGlideId);
      setResources(resourcesData);
    } catch (error) {
      console.error('Error in handleUpdateSpace:', error);
      setErrorMessage(error.message);
    }
  };

  const handleDeleteSpace = async (spaceId) => {
    // Implement space deletion logic here
    // You can set success or error messages similarly
  };

  const handleCreateBookingSlots = async (formData) => {
    try {
      const response = await fetch(`${BASE_URL_BOOKING_SLOTS}/create_recurring_booking_slots`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || 'Failed to create booking slots');
      }

      const data = await response.json();
      setSuccessMessage(data.message || 'Booking slots created successfully.');
    } catch (error) {
      console.error('Error in handleCreateBookingSlots:', error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      <h1 className="text-2xl font-bold mb-4">Mosque Dashboard</h1>
      {errorMessage && (
        <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg">
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg">
          {successMessage}
        </div>
      )}
      {isLoadingResources ? (
        <Skeleton className="h-10 w-full" />
      ) : (
        <div className="space-y-4">
          {resources.map((resource) => (
            <Card key={resource.resource_id} className="p-4">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-xl font-semibold">{resource.name}</h2>
                <div className="flex space-x-2">
                  <EditResourceDialog resource={resource} onUpdateResource={handleUpdateResource} />
                  <Button variant="destructive" onClick={() => handleDeleteResource(resource.resource_id)}>
                    Delete
                  </Button>
                </div>
              </div>
              <p className="text-gray-600">{resource.description}</p>
              {/* Spaces */}
              {resource.spaces && resource.spaces.length > 0 && (
                <div className="mt-4 space-y-2">
                  {resource.spaces.map((space) => (
                    <Card key={space.space_id} className="p-4 ml-4">
                      <div className="flex justify-between items-center mb-2">
                        <h3 className="text-lg font-semibold">{space.name}</h3>
                        <div className="flex space-x-2">
                          <EditSpaceDialog space={space} onUpdateSpace={handleUpdateSpace} />
                          <Button variant="destructive" onClick={() => handleDeleteSpace(space.space_id)}>
                            Delete
                          </Button>
                        </div>
                      </div>
                      <p className="text-gray-600">{space.description}</p>
                      <div className="mt-2">
                        <ManageBookingSlotsDialog resource={resource} space={space} onCreateBookingSlots={handleCreateBookingSlots} />
                      </div>
                    </Card>
                  ))}
                </div>
              )}
              {/* Manage booking slots for resource */}
              <div className="mt-4">
                <ManageBookingSlotsDialog resource={resource} onCreateBookingSlots={handleCreateBookingSlots} />
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

const EditResourceDialog = ({ resource, onUpdateResource }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(resource.name);
  const [description, setDescription] = useState(resource.description);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim()) {
      setError("Name is required.");
      return;
    }
    onUpdateResource(resource.resource_id, name, description);
    setOpen(false);
    setError("");
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Edit</Button>
      </DialogTrigger>
      <DialogContent>
        <h2 className="text-lg font-semibold mb-4">Edit Resource</h2>
        {error && (
          <div className="p-2 mb-2 text-sm text-red-700 bg-red-100 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button variant="ghost" type="button" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit">Save Changes</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const EditSpaceDialog = ({ space, onUpdateSpace }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState(space.name);
  const [description, setDescription] = useState(space.description);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim()) {
      setError("Name is required.");
      return;
    }
    onUpdateSpace(space.space_id, name, description);
    setOpen(false);
    setError("");
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Edit</Button>
      </DialogTrigger>
      <DialogContent>
        <h2 className="text-lg font-semibold mb-4">Edit Space</h2>
        {error && (
          <div className="p-2 mb-2 text-sm text-red-700 bg-red-100 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <Label htmlFor="description">Description</Label>
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="flex justify-end space-x-2">
            <Button variant="ghost" type="button" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit">Save Changes</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const ManageBookingSlotsDialog = ({ resource, space, onCreateBookingSlots }) => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [durationMonths, setDurationMonths] = useState(1);
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("17:00");
  const [status, setStatus] = useState("open");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (durationMonths < 1 || durationMonths > 3) {
      setError("Duration must be between 1 and 3 months.");
      return;
    }
    if (startTime >= endTime) {
      setError("Start time must be before end time.");
      return;
    }

    const formData = {
      resource_id: resource.resource_id,
      start_date: startDate.toISOString().split('T')[0],
      duration_months: parseInt(durationMonths, 10),
      start_time: startTime,
      end_time: endTime,
      status: status,
    };

    if (space) {
      formData.space_ids = [space.space_id];
    }

    onCreateBookingSlots(formData);
    setOpen(false);
    setError("");
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Manage Booking Slots</Button>
      </DialogTrigger>
      <DialogContent>
        <h2 className="text-lg font-semibold mb-4">Manage Booking Slots</h2>
        {error && (
          <div className="p-2 mb-2 text-sm text-red-700 bg-red-100 rounded-lg">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="start_date">Start Date</Label>
            <DatePickerDemo
              id="start_date"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className="w-full"
            />
          </div>
          <div>
            <Label htmlFor="duration_months">Duration (months)</Label>
            <Input
              type="number"
              id="duration_months"
              min="1"
              max="3"
              value={durationMonths}
              onChange={(e) => setDurationMonths(e.target.value)}
              className="w-full"
              required
            />
          </div>
          <div>
            <Label htmlFor="start_time">Start Time</Label>
            <TimePicker
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="end_time">End Time</Label>
            <TimePicker
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </div>
          <div>
            <Label htmlFor="status">Status</Label>
            <Select
              value={status}
              onValueChange={(value) => setStatus(value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="open">Open</SelectItem>
                <SelectItem value="closed">Closed</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-end space-x-2">
            <Button variant="ghost" type="button" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit">Create Booking Slots</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Home;
