import React from "react";
import { Button } from "components/shadcn/Button";
import { ScrollArea } from "components/shadcn/ScrollArea";

const TimeSlots = ({ slots = [], onSelectSlot, selectedSlotId = null }) => {
  if (!slots.length) {
    return (
      <div className="text-center py-4 text-muted-foreground">
        No available slots for this day
      </div>
    );
  }

  return (
    <ScrollArea className="h-[300px] w-full">
      <div className="flex flex-col gap-2 p-2">
        {slots.map((slot) => {
          const startTime = new Date(slot.start_datetime).toLocaleTimeString(
            [],
            {
              hour: "2-digit",
              minute: "2-digit",
            },
          );
          const endTime = new Date(slot.end_datetime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          return (
            <Button
              key={slot.booking_slot_id}
              variant={
                selectedSlotId === slot.booking_slot_id ? "default" : "outline"
              }
              className="w-full justify-start"
              onClick={() => onSelectSlot(slot)}
            >
              {startTime} - {endTime}
            </Button>
          );
        })}
      </div>
    </ScrollArea>
  );
};

export default TimeSlots;
