import axios from "axios";

const API_URL = "https://api.mosquely.com/v1/archive";

const createBucket = async (mosqueGlideId) => {
  const response = await axios.post(`${API_URL}/create_bucket`, {
    mosque_glide_id: mosqueGlideId,
  });
  return response;
};

const listArchive = async ({ mosqueGlideId, folderPath = "" }) => {
  const response = await axios.post(`${API_URL}/list_current_dir`, {
    mosque_glide_id: mosqueGlideId,
    folder_path: folderPath,
  });
  return response;
};

const uploadFile = async (formData) => {
  const response = await axios.post(`${API_URL}/upload_file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

const createFolder = async ({ mosqueGlideId, folderPath = "", folderName }) => {
  const response = await axios.post(`${API_URL}/create_folder`, {
    mosque_glide_id: mosqueGlideId,
    folder_path: folderPath,
    folder_name: folderName,
  });
  return response;
};

const renameFile = async ({
  mosqueGlideId,
  folderPath = "",
  oldName,
  newName,
}) => {
  const response = await axios.put(`${API_URL}/rename_file`, {
    mosque_glide_id: mosqueGlideId,
    folder_path: folderPath,
    old_name: oldName,
    new_name: newName,
  });
  return response;
};

const renameFolder = async ({
  mosqueGlideId,
  folderPath = "",
  oldName,
  newName,
}) => {
  const response = await axios.put(`${API_URL}/rename_folder`, {
    mosque_glide_id: mosqueGlideId,
    folder_path: folderPath,
    old_name: oldName,
    new_name: newName,
  });
  return response;
};

const moveFile = async ({
  mosqueGlideId,
  fileName,
  sourcePath = "",
  destinationPath = "",
}) => {
  const response = await axios.put(`${API_URL}/move_file`, {
    mosque_glide_id: mosqueGlideId,
    file_name: fileName,
    source_path: sourcePath,
    destination_path: destinationPath,
  });
  return response;
};

const moveFolder = async ({
  mosqueGlideId,
  folderName,
  sourcePath = "",
  destinationPath = "",
}) => {
  const response = await axios.put(`${API_URL}/move_folder`, {
    mosque_glide_id: mosqueGlideId,
    folder_name: folderName,
    source_path: sourcePath,
    destination_path: destinationPath,
  });
  return response;
};

const deleteFile = async ({ mosqueGlideId, folderPath = "", fileName }) => {
  const response = await axios.delete(`${API_URL}/delete_file`, {
    data: {
      mosque_glide_id: mosqueGlideId,
      folder_path: folderPath,
      file_name: fileName,
    },
  });
  return response;
};

const deleteFolder = async ({ mosqueGlideId, folderPath = "", folderName }) => {
  const response = await axios.delete(`${API_URL}/delete_folder`, {
    data: {
      mosque_glide_id: mosqueGlideId,
      folder_path: folderPath,
      folder_name: folderName,
    },
  });
  return response;
};

const getDownloadUrl = async ({ mosqueGlideId, folderPath = "", fileName }) => {
  const response = await axios.post(`${API_URL}/get_download_url`, {
    mosque_glide_id: mosqueGlideId,
    folder_path: folderPath,
    file_name: fileName,
  });
  return response;
};

const streamVideo = async ({ mosqueGlideId, folderPath, fileName }) => {
  const params = {
    mosque_glide_id: mosqueGlideId,
    folder_path: folderPath,
    file_name: fileName,
  };

  const response = await axios.get(`${API_URL}/stream_video`, {
    params,
    responseType: "blob",
    headers: {
      Range: "bytes=0-",
    },
  });

  return response;
};

const searchArchive = async ({ mosqueGlideId, searchText }) => {
  const response = await axios.post(`${API_URL}/search`, {
    mosque_glide_id: mosqueGlideId,
    search_text: searchText,
  });
  return response;
};

const archiveAPI = {
  createBucket,
  listArchive,
  uploadFile,
  createFolder,
  renameFile,
  renameFolder,
  moveFile,
  moveFolder,
  deleteFile,
  deleteFolder,
  getDownloadUrl,
  streamVideo,
  searchArchive,
};

export default archiveAPI;
