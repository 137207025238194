import React from "react";
import { Button } from "components/shadcn/Button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { dateUtils } from "../utils";

const CalendarHeader = ({ currentDate, onPrevMonth, onNextMonth }) => {
  // Get month and year directly from the Date object
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  return (
    <div className="flex justify-between items-center mb-4">
      <Button
        variant="ghost"
        size="icon"
        onClick={onPrevMonth}
        className="hover:bg-accent-hover p-2 rounded"
      >
        <ChevronLeft className="w-4 h-4" />
      </Button>
      <h2 className="text-base font-semibold">
        {month} {year}
      </h2>
      <Button
        variant="ghost"
        size="icon"
        onClick={onNextMonth}
        className="hover:bg-accent-hover p-2 rounded"
      >
        <ChevronRight className="w-4 h-4" />
      </Button>
    </div>
  );
};

export default CalendarHeader;
