import React from "react";
import {
  IoMoonOutline,
  IoSettingsOutline,
  IoChevronForward,
} from "react-icons/io5";
import {
  Card,
  CardContent,
  CardHeader,
} from "components/shadcn/Card";
import { Button } from "components/shadcn/Button";
import { Separator } from "components/shadcn/Separator";

const PlanItem = ({ name, price, onClick }) => (
  <Button
    variant="ghost"
    className="w-full flex items-center justify-between py-4 px-2"
    onClick={onClick}
  >
    <div className="flex items-center space-x-4">
      <IoMoonOutline className="h-5 w-5 text-muted-foreground" />
      <span>{name}</span>
    </div>
    {price && <span className="font-semibold">{price}</span>}
    <IoChevronForward className="h-5 w-5 text-muted-foreground" />
  </Button>
);

const Subscription = () => {
  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <div className="bg-primary/10 rounded-lg p-4 flex items-center space-x-3">
          <IoSettingsOutline className="h-5 w-5 text-primary" />
          <p>You are on the ? plan</p>
        </div>
      </CardHeader>

      <CardContent className="space-y-6">
        <div className="space-y-2 text-center text-muted-foreground">
          <p>
            On behalf of the mosque, we would like to extend our heartfelt
            gratitude for your generous assistance.
          </p>

          <p className="italic">
            As our beloved Prophet Muhammad ﷺ said, "Charity does not in any way
            decrease wealth."
          </p>

          <p className="font-medium text-primary">
            May Allah reward your contributions abundantly!
          </p>
        </div>

        <div className="space-y-2">
          <PlanItem name="Silver" price="£50.00" />
          <Separator />
          <PlanItem name="Gold" price="£100.00" />
          <Separator />
          <PlanItem name="Custom" />
        </div>

        <Button
          variant="outline"
          className="w-full flex items-center justify-center space-x-2"
        >
          <IoSettingsOutline className="h-5 w-5" />
          <span>Manage Subscription</span>
        </Button>
      </CardContent>
    </Card>
  );
};

export default Subscription;
