import React from "react";

function Payments() {
  return (
    <div>
      <h1>Payments Page</h1>
      <p>Manage payments and view transaction history here.</p>
    </div>
  );
}

export default Payments;
