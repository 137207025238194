export const DAYS_OF_WEEK = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];

export const MOCK_BOOKING_DATA = {
  booking_slots: {
    "2024-11-15": [
      {
        booking_slot_id: "c2cb904a-feed-40dc-b3f9-4d379378d073",
        end_datetime: "2024-11-15 18:00:00",
        space_id: "77777777-7777-7777-7777-777777777777",
        start_datetime: "2024-11-15 12:00:00",
        status: "open",
      },
      {
        booking_slot_id: "d3dc015b-gfee-51ed-c4f9-5e48049384084",
        end_datetime: "2024-11-15 22:00:00",
        space_id: "77777777-7777-7777-7777-777777777777",
        start_datetime: "2024-11-15 19:00:00",
        status: "open",
      },
    ],
    "2024-11-20": [
      {
        booking_slot_id: "8e98c485-a0dd-470d-ba4a-c9f3b42c4d25",
        end_datetime: "2024-11-20 18:00:00",
        space_id: "77777777-7777-7777-7777-777777777777",
        start_datetime: "2024-11-20 12:00:00",
        status: "open",
      },
    ],
  },
};

export const dateUtils = {
  getDaysInMonth: (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  },

  getDayOffset: (date) => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return (firstDay.getDay() + 6) % 7;
  },

  getMonthYear: (date) => {
    return {
      month: date.toLocaleString("default", { month: "long" }),
      year: date.getFullYear(),
    };
  },

  navigateMonth: (date, direction) => {
    return new Date(date.getFullYear(), date.getMonth() + direction, 1);
  },
};
