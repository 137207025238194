import React from "react";
import CalendarScheduler from "components/ui/CalendarScheduler";

const HallBooking = () => {
  return (
    <div>
      <CalendarScheduler />
    </div>
  );
};

export default HallBooking;
