import React, { useEffect } from "react";
import { HashRouter as Router, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import PublicRoutes from "./routes/PublicRoutes";
import AttendeeRoutes from "./routes/AttendeeRoutes";
import AdminRoutes from "./routes/AdminRoutes";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      dispatch({
        type: "auth/initialize",
        payload: JSON.parse(storedAuthState),
      });
    }
  }, [dispatch]);

  return (
    <Router>
      <Routes>
        {PublicRoutes}
        {AttendeeRoutes}
        {AdminRoutes}
      </Routes>
    </Router>
  );
}

export default App;
