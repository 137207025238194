import {
  createSlice,
  createAsyncThunk,
  isPending,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import archiveAPI from "api/archiveAPI";

// Helper function to create thunks with common logic
const createArchiveThunk = (
  actionName,
  apiFunction,
  transformArgs = (args) => args,
) => {
  return createAsyncThunk(
    `archive/${actionName}`,
    async (args, { getState, rejectWithValue }) => {
      const mosqueGlideId = getState().archive.mosqueGlideId;
      try {
        const response = await apiFunction({
          mosqueGlideId,
          ...transformArgs(args),
        });
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response?.data || error.message);
      }
    },
  );
};

// Async Thunks using the helper function
export const createBucket = createArchiveThunk(
  "createBucket",
  archiveAPI.createBucket,
);
export const listArchive = createArchiveThunk(
  "listArchive",
  archiveAPI.listArchive,
);
export const createFolder = createArchiveThunk(
  "createFolder",
  archiveAPI.createFolder,
);
export const renameFile = createArchiveThunk(
  "renameFile",
  archiveAPI.renameFile,
);
export const renameFolder = createArchiveThunk(
  "renameFolder",
  archiveAPI.renameFolder,
);
export const moveFile = createArchiveThunk("moveFile", archiveAPI.moveFile);
export const moveFolder = createArchiveThunk(
  "moveFolder",
  archiveAPI.moveFolder,
);
export const deleteFile = createArchiveThunk(
  "deleteFile",
  archiveAPI.deleteFile,
);
export const deleteFolder = createArchiveThunk(
  "deleteFolder",
  archiveAPI.deleteFolder,
);
export const getDownloadUrl = createArchiveThunk(
  "getDownloadUrl",
  archiveAPI.getDownloadUrl,
);

// Thunks with unique logic
export const uploadFile = createAsyncThunk(
  "archive/uploadFile",
  async (formData, { getState, rejectWithValue }) => {
    const mosqueGlideId = getState().archive.mosqueGlideId;
    formData.append("mosque_glide_id", mosqueGlideId);
    try {
      const response = await archiveAPI.uploadFile(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const streamVideo = createAsyncThunk(
  "archive/streamVideo",
  async ({ folderPath, fileName }, { getState, rejectWithValue }) => {
    const mosqueGlideId = getState().archive.mosqueGlideId;
    try {
      const response = await archiveAPI.streamVideo({
        mosqueGlideId,
        folderPath,
        fileName,
      });
      const videoUrl = URL.createObjectURL(response.data);
      return { videoUrl, fileName };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const searchArchive = createAsyncThunk(
  "archive/searchArchive",
  async ({ searchText }, { getState, rejectWithValue }) => {
    const mosqueGlideId = getState().archive.mosqueGlideId;
    try {
      const response = await archiveAPI.searchArchive({
        mosqueGlideId,
        searchText,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// New Thunk for navigating to a specific path
export const navigateToPath = createAsyncThunk(
  "archive/navigateToPath",
  async (path, { dispatch, rejectWithValue }) => {
    try {
      // Set the new path and clear existing archiveData
      dispatch(setCurrentFolderPath(path));
      // Fetch the archive data for the new path
      const result = await dispatch(listArchive({ folderPath: path })).unwrap();
      return result;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Initial State
const initialState = {
  loading: false,
  error: null,
  archiveData: {},
  searchResults: null,
  mosqueGlideId: null,
  currentFolderPath: "",
  previewFileUrl: null,
  previewFileName: null,
  previewFileType: null,
  isPreviewOpen: false,
  videoStreamUrl: null,
};

// Slice
const archiveSlice = createSlice({
  name: "archive",
  initialState,
  reducers: {
    setMosqueGlideId: (state, action) => {
      state.mosqueGlideId = action.payload;
    },
    setCurrentFolderPath: (state, action) => {
      state.currentFolderPath = action.payload;
      state.archiveData = {}; // Clear archiveData on path change
    },
    openFilePreview: (state, action) => {
      const { fileUrl, fileName, fileType } = action.payload;
      state.previewFileUrl = fileUrl;
      state.previewFileName = fileName;
      state.previewFileType = fileType;
      state.isPreviewOpen = true;
    },
    closeFilePreview: (state) => {
      state.previewFileUrl = null;
      state.previewFileName = null;
      state.previewFileType = null;
      state.isPreviewOpen = false;
      state.videoStreamUrl = null;
    },
    goToParentDirectory: (state) => {
      const currentPath = state.currentFolderPath;
      if (currentPath.includes("/")) {
        state.currentFolderPath = currentPath.substring(
          0,
          currentPath.lastIndexOf("/"),
        );
      } else {
        state.currentFolderPath = "";
      }
      state.archiveData = {}; // Clear archiveData when going up
    },
  },
  extraReducers: (builder) => {
    // Specific fulfilled cases first
    builder.addCase(streamVideo.fulfilled, (state, action) => {
      state.loading = false;
      state.previewFileUrl = action.payload.videoUrl;
      state.previewFileName = action.payload.fileName;
      state.previewFileType = "video";
      state.isPreviewOpen = true;
    });

    builder.addCase(searchArchive.fulfilled, (state, action) => {
      state.loading = false;
      state.searchResults = action.payload;
    });

    // Handle fulfilled state for thunks that update archiveData
    builder.addMatcher(
      isFulfilled(
        createBucket,
        listArchive,
        uploadFile,
        createFolder,
        renameFile,
        renameFolder,
        moveFile,
        moveFolder,
        deleteFile,
        deleteFolder,
        getDownloadUrl,
        navigateToPath, // Include navigateToPath
      ),
      (state, action) => {
        state.loading = false;
        state.archiveData = action.payload;
      },
    );

    // Handle common pending and rejected states
    builder.addMatcher(isPending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addMatcher(isRejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || action.error.message;
    });
  },
});

// Export Actions
export const {
  setMosqueGlideId,
  setCurrentFolderPath,
  openFilePreview,
  closeFilePreview,
  goToParentDirectory,
} = archiveSlice.actions;

// Export Reducer
export default archiveSlice.reducer;
