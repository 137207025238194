import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Card, CardContent } from "components/shadcn/Card";
import { Button } from "components/shadcn/Button";
import { Skeleton } from "components/shadcn/Skeleton";

const Feed = ({ selectedSection }) => {
  const [news, setNews] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("News");

  const fetchNews = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "http://localhost:10000/api/v1/news/get-all-news",
        {
          withCredentials: false,
        },
      );

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "text/xml");
      const items = xmlDoc.getElementsByTagName("item");

      const newsArray = Array.from(items).map((item, index) => {
        const pubDate = new Date(
          item.getElementsByTagName("pubDate")[0].textContent,
        );
        const formattedDate = formatDate(pubDate);
        const categories = Array.from(
          item.getElementsByTagName("category"),
        ).map((cat) => cat.textContent);

        return {
          id: `${index}-${item.getElementsByTagName("guid")[0].textContent}`,
          guid: item.getElementsByTagName("guid")[0].textContent,
          title: item.getElementsByTagName("title")[0].textContent,
          link: item.getElementsByTagName("link")[0].textContent,
          creator: item.getElementsByTagName("dc:creator")[0].textContent,
          category: categories[0],
          categories: categories,
          description: item.getElementsByTagName("description")[0].textContent,
          pubDate: formattedDate,
          rawDate: pubDate,
          enclosure: item
            .getElementsByTagName("enclosure")[0]
            .getAttribute("url"),
        };
      });

      newsArray.sort((a, b) => b.rawDate - a.rawDate);
      setNews(newsArray);
    } catch (error) {
      console.error("Error fetching news:", error);
      setError("Failed to fetch news. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (selectedSection === "News") {
      fetchNews();
    }
  }, [selectedSection, fetchNews]);

  const formatDate = (date) => {
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const setCategory = (category) => {
    setSelectedCategory(category);
  };

  if (error) {
    return (
      <div className="text-center py-4 text-red-500 font-semibold">{error}</div>
    );
  }

  if (loading) {
    return (
      <div className="space-y-4">
        <div className="flex gap-2 mb-4">
          {[1, 2, 3].map((i) => (
            <Skeleton key={i} className="h-10 w-24" />
          ))}
        </div>
        <Card>
          <CardContent className="p-0">
            <div className="flex flex-col md:flex-row">
              <Skeleton className="h-[300px] md:w-1/2" />
              <div className="p-6 space-y-4 md:w-1/2">
                <Skeleton className="h-8 w-3/4" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-2/3" />
              </div>
            </div>
          </CardContent>
        </Card>
        <div className="space-y-4 mt-4">
          {[1, 2, 3].map((i) => (
            <Card key={i}>
              <CardContent className="p-4">
                <div className="flex gap-4">
                  <Skeleton className="h-24 w-36" />
                  <div className="flex-1 space-y-2">
                    <Skeleton className="h-6 w-3/4" />
                    <Skeleton className="h-4 w-1/4" />
                    <Skeleton className="h-4 w-full" />
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  const filteredNews = news.filter(
    (item) =>
      item.category === selectedCategory ||
      item.categories.includes(selectedCategory),
  );

  return (
    <div className="space-y-4">
      <div className="flex gap-2 overflow-x-auto pb-2">
        {["News", "Opinion", "Israel-Palestine Conflict"].map((category) => (
          <Button
            key={category}
            variant={selectedCategory === category ? "default" : "outline"}
            onClick={() => setCategory(category)}
            className="whitespace-nowrap"
          >
            {category}
          </Button>
        ))}
      </div>

      {filteredNews.length > 0 && (
        <Card className="overflow-hidden">
          <CardContent className="p-0">
            <div className="flex flex-col md:flex-row">
              <img
                src={
                  filteredNews[0].enclosure ||
                  "https://via.placeholder.com/800x400"
                }
                alt={filteredNews[0].title}
                className="h-[300px] md:w-1/2 object-cover"
              />
              <div className="p-6 md:w-1/2">
                <a
                  href={filteredNews[0].link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  <h2 className="text-2xl font-bold mb-4">
                    {filteredNews[0].title}
                  </h2>
                </a>
                <p className="text-gray-600 mb-4">
                  {filteredNews[0].description}
                </p>
                <p className="text-sm text-gray-500">
                  By {filteredNews[0].creator}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>
      )}

      <Card>
        <CardContent className="p-4">
          <h3 className="text-lg font-bold text-orange-600 mb-4">
            LIVE UPDATES
          </h3>
          <div className="space-y-4">
            {filteredNews.slice(1, 4).map((item) => (
              <div key={item.id} className="pb-4 border-b last:border-0">
                <span className="text-sm text-gray-500">{item.pubDate}</span>
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block hover:underline"
                >
                  <p className="font-semibold my-2">{item.title}</p>
                </a>
                <p className="text-sm text-gray-600">By {item.creator}</p>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>

      <div className="space-y-4">
        {filteredNews.slice(4).map((item) => (
          <Card key={item.id}>
            <CardContent className="p-4">
              <div className="flex flex-col md:flex-row gap-4">
                <img
                  src={item.enclosure || "https://via.placeholder.com/150x100"}
                  alt={item.title}
                  className="w-full md:w-48 h-32 object-cover rounded"
                />
                <div className="flex-1">
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    <h3 className="font-semibold mb-2">{item.title}</h3>
                  </a>
                  <span className="text-sm text-gray-500 block mb-2">
                    {item.pubDate}
                  </span>
                  <p className="text-gray-600 mb-2">{item.description}</p>
                  <p className="text-sm text-gray-500 italic mb-2">
                    By {item.creator}
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {item.categories.map((cat, index) => (
                      <span
                        key={index}
                        className="text-xs bg-gray-100 px-2 py-1 rounded"
                      >
                        {cat}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Feed;
