import React from "react";

function AskImaam() {
  return (
    <div>
      <h1>Ask Imaam Page</h1>
      <p>This is the page where users can ask questions to the Imaam.</p>
    </div>
  );
}

export default AskImaam;
