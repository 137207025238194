import React from "react";

function ManageMosque() {
  return (
    <div>
      <h1>Manage Mosque Page</h1>
      <p>This page allows management and settings for the mosque.</p>
    </div>
  );
}

export default ManageMosque;
