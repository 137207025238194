import React from "react";
import { DAYS_OF_WEEK } from "../utils";

const WeekdayHeader = () => (
  <div className="grid grid-cols-7 mb-2 text-center font-medium text-xs">
    {DAYS_OF_WEEK.map((day) => (
      <div key={day} className="p-2 text-muted-foreground">
        {day}
      </div>
    ))}
  </div>
);

export default WeekdayHeader;
