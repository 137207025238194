import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent } from "components/shadcn/Card";

const ServiceItem = ({ service }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dashboard/attendee/services/${service.path}`);
  };

  return (
    <Card
      className="cursor-pointer hover:bg-accent transition-colors duration-200"
      onClick={handleClick}
    >
      <CardContent className="flex flex-col items-center justify-center p-3 sm:p-4">
        <div className="w-12 h-12 sm:w-16 sm:h-16 mb-2 flex items-center justify-center">
          <img
            src={service.icon}
            alt={service.name}
            className="w-full h-full object-contain"
          />
        </div>
        <span className="text-xs sm:text-sm font-medium text-center">
          {service.name}
        </span>
      </CardContent>
    </Card>
  );
};

export default ServiceItem;
