import React from "react";

const QuranicStudies = () => {
  return (
    <div>
      <h1>Tahfizul Qur'an</h1>
      <p>
        Welcome to our Tahfizul Qur'an page. Here you can find information about
        our Qur'an memorization programs.
      </p>
      {/* Add more content specific to Tahfizul Qur'an */}
    </div>
  );
};

export default QuranicStudies;
