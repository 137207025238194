import React from "react";
import styles from "./MosqueInformation.module.css";

const MosqueInformation = () => {
  return (
    <div className={styles.mosqueInfo}>
      <h2>Mosque Information</h2>
      <p>This section contains important information about our mosque.</p>
      {/* Add more content specific to mosque information */}
    </div>
  );
};

export default MosqueInformation;
