import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle,
} from "components/shadcn/Card";

const PrivacyPolicy = () => {
  return (
    <div className="space-y-6 p-6">
      <Card>
        <CardHeader>
          <CardTitle>Privacy Policy</CardTitle>
        </CardHeader>
        <CardContent>
          <section>
            <h2 className="text-lg font-semibold mb-2">Introduction</h2>
            <p>
              Welcome to Mosquely. We are committed to protecting your privacy
              and ensuring that your personal information is handled in a safe
              and responsible manner. This Privacy Policy outlines how we
              collect, use, and safeguard your information when you use our
              website and services.
            </p>
          </section>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Information We Collect</CardTitle>
        </CardHeader>
        <CardContent>
          <p>We may collect the following types of information:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>
              <strong>Personal Information:</strong> Name, email address, phone
              number, mailing address, and other contact details.
            </li>
            <li>
              <strong>Financial Information:</strong> Payment details, donation
              amounts, zakat contributions.
            </li>
            <li>
              <strong>Service Bookings:</strong> Details related to hall
              services, nikah services, and other bookings.
            </li>
            <li>
              <strong>Content Uploads:</strong> Videos, lectures, news articles,
              and social media content you upload.
            </li>
            <li>
              <strong>Usage Data:</strong> Information on how you navigate and
              interact with our website.
            </li>
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>How We Use Your Information</CardTitle>
        </CardHeader>
        <CardContent>
          <p>We use the collected information for the following purposes:</p>
          <ul className="list-disc pl-6 space-y-1">
            <li>Processing payments, donations, and zakat contributions.</li>
            <li>Managing and facilitating service bookings.</li>
            <li>
              Uploading and displaying your content such as videos and lectures.
            </li>
            <li>Improving our website and services based on usage data.</li>
            <li>
              Communicating with you about updates, news, and important
              information.
            </li>
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>How We Share Your Information</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            We do not sell, trade, or otherwise transfer your personal
            information to outside parties except in the following
            circumstances:
          </p>
          <ul className="list-disc pl-6 space-y-1">
            <li>
              <strong>Service Providers:</strong> Trusted third-party vendors
              who assist us in operating our website, conducting our business,
              or servicing you.
            </li>
            <li>
              <strong>Legal Requirements:</strong> When required by law,
              regulation, or legal process.
            </li>
            <li>
              <strong>Protection of Rights:</strong> To protect the rights,
              property, and safety of Mosquely, our users, or others.
            </li>
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Security of Your Information</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            We implement a variety of security measures to maintain the safety
            of your personal information. However, no method of transmission
            over the internet or electronic storage is 100% secure, and we
            cannot guarantee absolute security.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Your Rights</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            Depending on your location, you may have the following rights
            regarding your personal information:
          </p>
          <ul className="list-disc pl-6 space-y-1">
            <li>Accessing your personal data.</li>
            <li>Requesting correction of inaccurate information.</li>
            <li>Requesting deletion of your personal data.</li>
            <li>
              Objecting to or restricting certain data processing activities.
            </li>
          </ul>
          <p>
            To exercise these rights, please contact us using the information
            provided below.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Changes to This Privacy Policy</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            We may update our Privacy Policy from time to time. Any changes will
            be posted on this page, and the "Last Updated" date will be revised
            accordingly. We encourage you to review this Privacy Policy
            periodically to stay informed about how we are protecting your
            information.
          </p>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Contact Us</CardTitle>
        </CardHeader>
        <CardContent>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at:
          </p>
          <ul className="list-disc pl-6 space-y-1">
            <li>
              <strong>Email:</strong> admin@mosquely.co.uk
            </li>
            <li>
              <strong>Phone:</strong> +44 74 5502 5053
            </li>
          </ul>
        </CardContent>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;
