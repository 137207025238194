import React from "react";

function Events() {
  return (
    <div>
      <h1>Events Page</h1>
      <p>Here you can view and manage events.</p>
    </div>
  );
}

export default Events;
