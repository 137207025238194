import React, { useState } from "react";
import { Card, CardContent } from "components/shadcn/Card";
import Feed from "./feed/Feed";
import MosqueInformation from "./mosque-information/MosqueInformation";
import Reminders from "./reminders/Reminders";
import mosqueIcon from "assets/news/mosque_icon.png";
import newsIcon from "assets/news/news_icon.png";
import reminderIcon from "assets/news/reminder_icon.png";

const News = () => {
  const [selectedSection, setSelectedSection] = useState("News");

  const sections = [
    { name: "News", icon: newsIcon },
    { name: "Mosque", icon: mosqueIcon, title: "Mosque Information" },
    { name: "Reminders", icon: reminderIcon },
  ];

  return (
    <div className="container mx-auto p-6">
      <div className="w-full p-4">
        <div className="flex gap-4">
          {sections.map((section) => (
            <Card
              key={section.name}
              className={`flex-1 cursor-pointer transition-all duration-300 ${
                selectedSection === section.name ? "bg-accent" : ""
              }`}
              onClick={() => setSelectedSection(section.name)}
            >
              <CardContent className="flex flex-col items-center p-4">
                <div className="w-20 h-20 flex items-center justify-center mb-2">
                  <img
                    src={section.icon}
                    alt={section.name}
                    className="w-full h-full object-contain"
                  />
                </div>
                <span className="text-sm font-medium">
                  {section.title || section.name}
                </span>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      <div className="mt-6">
        {selectedSection === "News" && (
          <Feed selectedSection={selectedSection} />
        )}
        {selectedSection === "Mosque" && <MosqueInformation />}
        {selectedSection === "Reminders" && <Reminders />}
      </div>
    </div>
  );
};

export default News;
