import React from "react";

const IslamicSchool = () => {
  return (
    <div>
      <h1>Our IslamicSchool</h1>
      <p>
        Welcome to our IslamicSchool page. Here you can find information about our
        Islamic education programs.
      </p>
      {/* Add more content specific to the IslamicSchool */}
    </div>
  );
};

export default IslamicSchool;
