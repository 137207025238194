// src/layouts/AdminLayout.js
import React from "react";
import { Outlet, useLocation, Link } from "react-router-dom";
import { AppSidebar } from "components/admin/sidebar/app_sidebar";
import MobileNavigation from "pages/dashboard/admin/MobileNavigation";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "components/shadcn/Breadcrumb";
import { Separator } from "components/shadcn/Separator";
import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "components/shadcn/Sidebar";

const AdminLayout = () => {
  const location = useLocation();

  const getBreadcrumbs = () => {
    const pathSegments = location.pathname
      .split("/")
      .filter(
        (segment) =>
          segment && !["dashboard", "home", "admin"].includes(segment),
      );

    if (pathSegments.length === 0) return null;

    let currentPath = "/dashboard/admin";

    return (
      <BreadcrumbList>
        {pathSegments.map((segment, index) => {
          currentPath += `/${segment}`;
          const isLast = index === pathSegments.length - 1;
          const title =
            segment.charAt(0).toUpperCase() +
            segment.slice(1).replace(/-/g, " ");

          return (
            <React.Fragment key={currentPath}>
              <BreadcrumbItem>
                {isLast ? (
                  <BreadcrumbPage>{title}</BreadcrumbPage>
                ) : (
                  <BreadcrumbLink asChild>
                    <Link to={currentPath}>{title}</Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>
              {!isLast && <BreadcrumbSeparator />}
            </React.Fragment>
          );
        })}
      </BreadcrumbList>
    );
  };
  const breadcrumbs = getBreadcrumbs();

  return (
    <SidebarProvider>
      <div className="flex min-h-screen">
        <AppSidebar className="border-r" />
        <div className="flex-1">
          <SidebarInset>
            <header className="flex h-16 shrink-0 items-center gap-2 border-b transition-[width,height] ease-linear group-has-[data-collapsible=icon]/sidebar-wrapper:h-12">
              <div className="flex items-center gap-2 px-4">
                <SidebarTrigger className="-ml-1" />
                {breadcrumbs && (
                  <>
                    <Separator orientation="vertical" className="mr-2 h-4" />
                    <Breadcrumb className="overflow-hidden">
                      {breadcrumbs}
                    </Breadcrumb>
                  </>
                )}
              </div>
            </header>
            <main className="flex flex-1 flex-col gap-4 p-4 pb-20 md:gap-8 md:p-6 md:pb-8">
              <Outlet />
            </main>
            <MobileNavigation />
          </SidebarInset>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default AdminLayout;
