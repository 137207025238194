import React from "react";
import { Button } from "components/shadcn/Button";
import { Input } from "components/shadcn/Input";
import { Facebook, Twitter, Instagram, Youtube } from "lucide-react";
import Logo from "assets/logo.webp";

export default function Footer() {
  // Define base routes for navigation
  const routes = {
    home: "/",
    socials: {
      facebook: "https://facebook.com/mosquely",
      twitter: "https://twitter.com/mosquely",
      instagram: "https://instagram.com/mosquely",
      youtube: "https://youtube.com/mosquely",
    },
    mosques: {
      administration: "/mosques/administration",
      payments: "/mosques/payments",
      mediaManagement: "/mosques/media",
    },
    users: {
      subscription: "/users/subscription",
      bookings: "/users/bookings",
      media: "/users/media",
    },
    resources: {
      about: "/about",
      blog: "/blog",
      roadmaps: "/roadmaps",
      support: "/support",
    },
  };

  return (
    <footer className="w-full py-8 px-4 sm:px-6 lg:px-8 border-t bg-gray-50">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-start mb-8">
          {/* Logo and Social Section */}
          <div className="mb-8 md:mb-0 md:w-1/4 md:mr-16">
            <a href={routes.home} className="flex items-center mb-4">
              <img src={Logo} alt="Mosquely Logo" className="h-12 w-auto" />
            </a>
            <p className="text-sm mb-4 max-w-[240px] text-gray-700">
              Connecting communities through faith and technology.
            </p>
            <div className="flex gap-4">
              {Object.entries(routes.socials).map(([platform, url]) => (
                <a
                  key={platform}
                  href={url}
                  className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
                  aria-label={`Follow us on ${platform}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {platform === "facebook" && <Facebook className="h-6 w-6" />}
                  {platform === "twitter" && <Twitter className="h-6 w-6" />}
                  {platform === "instagram" && (
                    <Instagram className="h-6 w-6" />
                  )}
                  {platform === "youtube" && <Youtube className="h-6 w-6" />}
                </a>
              ))}
            </div>
          </div>

          {/* Links Sections */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 md:w-3/4">
            {/* Mosques */}
            <div>
              <h3 className="font-semibold mb-4 text-gray-800">Mosques</h3>
              <ul className="space-y-2">
                {Object.entries(routes.mosques).map(([key, path]) => (
                  <li key={key}>
                    <a
                      href={path}
                      className="text-sm hover:text-gray-800 transition-colors duration-200"
                    >
                      {key.charAt(0).toUpperCase() +
                        key.slice(1).replace(/([A-Z])/g, " $1")}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Users */}
            <div>
              <h3 className="font-semibold mb-4 text-gray-800">Users</h3>
              <ul className="space-y-2">
                {Object.entries(routes.users).map(([key, path]) => (
                  <li key={key}>
                    <a
                      href={path}
                      className="text-sm hover:text-gray-800 transition-colors duration-200"
                    >
                      {key.charAt(0).toUpperCase() +
                        key.slice(1).replace(/([A-Z])/g, " $1")}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Resources */}
            <div>
              <h3 className="font-semibold mb-4 text-gray-800">Resources</h3>
              <ul className="space-y-2">
                {Object.entries(routes.resources).map(([key, path]) => (
                  <li key={key}>
                    <a
                      href={path}
                      className="text-sm hover:text-gray-800 transition-colors duration-200"
                    >
                      {key.charAt(0).toUpperCase() +
                        key.slice(1).replace(/([A-Z])/g, " ")}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row items-center gap-4 pt-8 border-t">
          <div className="flex gap-4">
            <Button variant="outline" size="sm" asChild>
              <a href="/archive">Archive</a>
            </Button>
            <Button variant="outline" size="sm" asChild>
              <a href="/contact">Contact Sales</a>
            </Button>
            <Button variant="outline" size="sm" asChild>
              <a href="/signin">Sign In</a>
            </Button>
          </div>
          <div className="md:ml-auto mt-4 md:mt-0">
            <Input
              type="email"
              placeholder="Enter your email"
              className="w-full max-w-xs"
            />
          </div>
        </div>

        {/* Copyright Notice */}
        <div className="text-center mt-8 text-sm text-gray-600">
          © {new Date().getFullYear()} Mosquely. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
