import imageIcon from "assets/archive/image_icon.svg";
import videoIcon from "assets/archive/video_icon.svg";
import audioIcon from "assets/archive/audio_icon.svg";
import pdfIcon from "assets/archive/pdf_icon.svg";
import wordIcon from "assets/archive/word_icon.svg";
import excelIcon from "assets/archive/excel_icon.svg";
import zipIcon from "assets/archive/zip_icon.svg";
import defaultFileIcon from "assets/archive/default_file_icon.svg";
import defaultFolderIcon from "assets/archive/default_folder_icon.svg";
import imageFolderIcon from "assets/archive/image_folder_icon.svg";
import videoFolderIcon from "assets/archive/video_folder_icon.svg";
import audioFolderIcon from "assets/archive/audio_folder_icon.svg";

export const getFileTypeAndIcon = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();

  // Video formats
  if (["mp4", "avi", "mov", "mkv", "webm", "m4v"].includes(extension)) {
    return { type: "video", icon: videoIcon };
  }

  // Image formats
  if (["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(extension)) {
    return { type: "image", icon: imageIcon };
  }

  // Audio formats
  if (["mp3", "wav", "ogg", "m4a", "aac", "flac"].includes(extension)) {
    return { type: "audio", icon: audioIcon };
  }

  // Document formats
  if (["pdf"].includes(extension)) {
    return { type: "pdf", icon: pdfIcon };
  }

  if (["doc", "docx"].includes(extension)) {
    return { type: "document", icon: wordIcon };
  }

  if (["xls", "xlsx"].includes(extension)) {
    return { type: "spreadsheet", icon: excelIcon };
  }

  // Archive formats
  if (["zip", "rar", "7z"].includes(extension)) {
    return { type: "archive", icon: zipIcon };
  }

  // Default case
  return { type: "file", icon: defaultFileIcon };
};

export const formatFileSize = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};
