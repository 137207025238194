"use client";

import React, { useState, useRef, useEffect } from "react";
import { Button } from "components/shadcn/Button";
import Logo from "assets/logo.webp";
import {
  X,
  Menu,
  Settings,
  CreditCard,
  Image as ImageIcon,
  Users,
  Star,
  Calendar,
  Info,
  Edit3,
  Map,
  LifeBuoy,
  Layout,
  Tag,
  Phone,
  LogIn,
} from "lucide-react";

const MobileNavigation = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("overflow-hidden");
    };
  }, [isMobileMenuOpen]);

  return (
    <>
      <button
        className="md:hidden bg-white shadow-md rounded-lg p-3 ml-4 flex items-center justify-center text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        onClick={toggleMobileMenu}
        aria-label="Toggle mobile menu"
        aria-expanded={isMobileMenuOpen}
        aria-controls="mobile-menu"
      >
        {isMobileMenuOpen ? (
          <X className="h-6 w-6" />
        ) : (
          <Menu className="h-6 w-6" />
        )}
      </button>

      <div
        id="mobile-menu"
        ref={mobileMenuRef}
        className={`md:hidden fixed inset-0 bg-white transform ${
          isMobileMenuOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out overflow-y-auto`}
      >
        <div className="flex items-center justify-between p-4 border-b">
          <a href="/" className="flex items-center">
            <img src={Logo} alt="Logo" className="h-8 w-auto" />
          </a>
          <button
            className="p-2 rounded-md text-gray-700 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={closeMobileMenu}
            aria-label="Close mobile menu"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        <ul className="flex flex-col space-y-2 p-4">
          <li>
            <a
              href="/mosques"
              className="flex items-center px-2 py-1 rounded hover:bg-gray-100"
            >
              <Settings className="h-6 w-6 mr-2" aria-hidden="true" />
              Mosques
            </a>
            <ul className="ml-6 mt-1 space-y-1">
              <ListItemMobile
                href="/mosques/administration"
                title="Administration"
                icon={<Settings className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
              <ListItemMobile
                href="/mosques/payments"
                title="Payments"
                icon={
                  <CreditCard className="h-5 w-5 mr-2" aria-hidden="true" />
                }
              />
              <ListItemMobile
                href="/mosques/media-management"
                title="Media Management"
                icon={<ImageIcon className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
            </ul>
          </li>

          <li>
            <a
              href="/users"
              className="flex items-center px-2 py-1 rounded hover:bg-gray-100"
            >
              <Users className="h-6 w-6 mr-2" aria-hidden="true" />
              Users
            </a>
            <ul className="ml-6 mt-1 space-y-1">
              <ListItemMobile
                href="/users/subscription"
                title="Subscription"
                icon={<Star className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
              <ListItemMobile
                href="/users/service-bookings"
                title="Service Bookings"
                icon={<Calendar className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
              <ListItemMobile
                href="/users/stay-connected"
                title="Stay Connected"
                icon={<ImageIcon className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
            </ul>
          </li>

          <li>
            <a
              href="/resources"
              className="flex items-center px-2 py-1 rounded hover:bg-gray-100"
            >
              <Map className="h-6 w-6 mr-2" aria-hidden="true" />
              Resources
            </a>
            <ul className="ml-6 mt-1 space-y-1">
              <ListItemMobile
                href="/resources/about-us"
                title="About Us"
                icon={<Info className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
              <ListItemMobile
                href="/resources/blog"
                title="Blog"
                icon={<Edit3 className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
              <ListItemMobile
                href="/resources/roadmaps"
                title="Roadmaps"
                icon={<Map className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
              <ListItemMobile
                href="/resources/support"
                title="Support"
                icon={<LifeBuoy className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
              <ListItemMobile
                href="/dashboard/attendee"
                title="Dashboard"
                icon={<Layout className="h-5 w-5 mr-2" aria-hidden="true" />}
              />
            </ul>
          </li>

          <li>
            <a
              href="/pricing"
              className="flex items-center px-2 py-1 rounded hover:bg-gray-100"
            >
              <Tag className="h-6 w-6 mr-2" aria-hidden="true" />
              Pricing
            </a>
          </li>

          <li className="mt-4">
            <Button
              variant="outline"
              className="w-full mb-2 flex items-center justify-center"
            >
              <Phone className="h-6 w-6 mr-2" aria-hidden="true" />
              Contact Sales
            </Button>
            <Button className="w-full flex items-center justify-center bg-black text-white">
              <LogIn className="h-6 w-6 mr-2" aria-hidden="true" />
              Sign In
            </Button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default MobileNavigation;

// ListItemMobile Component
const ListItemMobile = ({ href, title, icon }) => (
  <a
    href={href}
    className="flex items-center px-2 py-1 rounded hover:bg-gray-100"
  >
    {icon}
    {title}
  </a>
);
