import React from "react";
import TextRevealByWord from "components/magicui/TextRevealByWord";

const Body = () => {
  return (
    <div className="bg-white">
      {/* Add your body content here */}
      <div className="container mx-auto py-12">
    
        <div className="z-10 flex min-h-64 items-center justify-center rounded-lg border bg-white dark:bg-black text-center">
          <TextRevealByWord text="Mosquely helps you spend more time on things that matter." />
        </div>
      </div>
    </div>
  );
};

export default Body;
